import { BrowserRouter, Route, Routes } from 'react-router-dom'
import useMetaTags from 'react-metatags-hook'
import { PropsWithChildren } from 'react'
import BaseContainer from '../containers/BaseContainer'
import ErrorBoundaries from '../containers/ErrorBoundaries'
import NotFound from '../containers/404'
import { useAppSelector } from '../hooks'
import { ITextLibrary } from '../interfaces'
import { WHATSAPP_ME_URL } from '../consts/defaultConfigValues/defaultConstants'
import Config from '../containers/Config'
import ErrorMessage, { ActionType } from '../components/ErrorMessage'
import getUrlParams from '../utils/getUrlParams'

const Index = ({ children }: PropsWithChildren) => {
  const config = useAppSelector((state) => state.defaultSlice.config)
  const loading = useAppSelector((state) => state.defaultSlice.loading)
  const globalError = useAppSelector((state) => state.defaultSlice.globalError)
  const errorMessage = useAppSelector(
    (state) => state.defaultSlice.errorMessage,
  )
  const showErrorTitle = useAppSelector(
    (state) => state.defaultSlice.showErrorTitle,
  )
  const sessionData = useAppSelector((state) => state.defaultSlice.sessionData)
  const onErrorCallback = useAppSelector(
    (state) => state.defaultSlice.onErrorCallback,
  )
  const { errors = {} } = (config?.texts || {}) as ITextLibrary
  const {
    globalError: errorTitle = '',
    backToWhatsapp = `Send 'Hi' to Chatbot`,
    botMessage = 'Hi',
    tryAgain = 'Try Again',
  } = errors
  const onErrorAction: ActionType = onErrorCallback
    ? ActionType.reload
    : ActionType.navigate
  const buttonErrorText = onErrorCallback ? tryAgain : backToWhatsapp
  const phoneNumber = sessionData?.workflow?.channelUid || config?.phoneNumber
  const wameUrl = encodeURI(
    `${WHATSAPP_ME_URL}${phoneNumber}?text=${botMessage}`,
  )
  let errorCallBack = onErrorCallback
  if (phoneNumber) {
    errorCallBack = () => window.location.replace(wameUrl)
  }

  useMetaTags(
    {
      title: config?.metatags?.title,
      description: config?.metatags?.description,
      charset: config?.metatags?.charset,
      lang: config?.metatags?.lang,
      metas: [{ name: 'keywords', content: config?.metatags?.keywords }],
      links: [{ rel: 'icon', href: config?.metatags?.favicon }],
    },
    [],
  )

  const { baseName } = getUrlParams(window.location.pathname)
  return (
    <BrowserRouter basename={baseName}>
      <Config>
        {globalError ? (
          <ErrorMessage
            message={errorMessage || ''}
            title={showErrorTitle ? errorTitle.toString() : ''}
            callbackMessage={buttonErrorText.toString()}
            callBack={errorCallBack}
            action={onErrorAction}
          />
        ) : (
          <Routes>
            {!loading
              ? config &&
                config.routes &&
                config.routes.map(({ route, exact, container }) => (
                  <Route
                    path={route}
                    key={route}
                    element={
                      <ErrorBoundaries>
                        <BaseContainer
                          container={config.containers[container]}
                          containerName={container}
                          modals={config.modals || {}}
                        />
                        {children}
                      </ErrorBoundaries>
                    }
                  />
                ))
              : null}
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </Config>
    </BrowserRouter>
  )
}

export default Index
