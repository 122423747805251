/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Icomponent,
  IConfig,
  IContainers,
  IContainersObject,
  IModal,
  IModalsObject,
  ITextLibrary,
  Localization,
} from '../interfaces'
import {
  DEFAULT_CONTAINERS_PROPS,
  DEFAULT_MODALS_PROPS,
} from '../consts/defaultConfigValues/defaultContainersProps'
import DEFAULT_COMPONENTS_PROPS from '../consts/defaultConfigValues/defaultProps'
import defaultConfig from '../consts/defaultConfig'
import defaultTexts from '../consts/defaultConfigValues/defaultTexts'
import deepMerge from './deepMerge'
import processData from './processData'

/**
 * Returns a processed config with default props and some initial props pre-processed
 * @param config original config data
 */
export function processConfig(
  config: IConfig,
  language?: Localization,
): IConfig {
  const mergedConfig = deepMerge(defaultConfig, config)
  let { containers, modals } = mergedConfig
  const { options = {}, texts: configTexts } = mergedConfig
  const customLanguage: ITextLibrary = language
    ? (configTexts[language] as unknown as ITextLibrary)
    : configTexts
  const localization = language || options.localization || Localization.En
  const texts = defaultTexts[localization] || defaultTexts.en
  const mergedTexts = deepMerge(texts, customLanguage)

  containers = processContainers(
    containers,
    DEFAULT_CONTAINERS_PROPS,
    mergedConfig,
  ) as IContainersObject

  if (modals) {
    modals = processContainers(
      modals,
      DEFAULT_MODALS_PROPS,
      mergedConfig,
    ) as IModalsObject
  }

  return {
    ...mergedConfig,
    containers,
    modals,
    texts: mergedTexts,
  }
}

/**
 * Process the data of the containers or modals in a config, including the components
 * @param containers Object of containers or modals
 * @param defaultProps List of default props for the container
 * @param config Config data, to be used as data reference
 */
export function processContainers(
  containers: IContainersObject | IModalsObject,
  defaultProps: typeof DEFAULT_CONTAINERS_PROPS | typeof DEFAULT_MODALS_PROPS,
  config: IConfig,
) {
  Object.keys(containers).forEach((key) => {
    let container: IContainers | IModal = {
      ...containers[key],
    }
    const { skipDefaults } = container
    const containerDefaults = defaultProps[key]
    // process default props of the container
    if (containerDefaults && !skipDefaults) {
      container = deepMerge(containerDefaults, container) as
        | IContainers
        | IModal
    }
    // process default props for header, body and footer components
    const { header, body, footer } = container

    const newHeader = header
      ? {
          ...header,
          components: getComponentsProps(header.components, config),
        }
      : undefined

    const newBody = {
      ...body,
      components: getComponentsProps(body.components, config),
    }

    const newFooter = footer
      ? {
          ...footer,
          components: getComponentsProps(footer.components, config),
        }
      : undefined

    containers[key] = {
      ...container,
      header: newHeader,
      body: newBody,
      footer: newFooter,
    }
  })
  return containers
}

/**
 * Process the props of a component, merging the default props and then pre-processing some initial variables
 * @param components List of components
 * @param config Config data, to be used as data reference
 */
export function getComponentsProps(
  components: Icomponent[],
  config: IConfig,
): Icomponent[] {
  return components?.map((component) => {
    const source = { state: { defaultSlice: { config } } }
    const { defaultProps, props = {} } = component
    const defaults = DEFAULT_COMPONENTS_PROPS[
      defaultProps!
    ] as Icomponent['props']
    const processedDefaults = processData(defaults || {}, source, true)
    const processedProps = processData(props, source, true)
    const newProps = defaultProps
      ? deepMerge(processedDefaults, processedProps)
      : processedProps
    return {
      ...component,
      props: newProps,
    }
  })
}

export default processConfig
