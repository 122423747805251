import { useCallback, useRef } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { ApolloError } from '@apollo/client'
import Hotjar from '@hotjar/browser'
import {
  SubItems,
  SubItem,
} from '@engyalo/delivery-ui-components/lib/interfaces/catalog'
import { useLocation, useSearchParams } from 'react-router-dom'
import { IProductCart } from '../../graphQL/mutations/cartRemoveProduct/types'
import useCartRemoveProduct from '../../graphQL/mutations/cartRemoveProduct'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import onRemoveProduct from '../../utils/onRemoveProduct'
import { useGetCart } from './useGetCart'
import { debounceById } from '../../utils/debounce'
import { IGenericFunction, IProduct } from '../../interfaces'
import { onCartResponse } from '../../utils/onCartResponse'
import { Referrers } from '../../consts/defaultConfigValues/defaultConstants'
import { generateDataDogContext } from '../../utils/dataDog'
import getAlertErrorMessage, {
  AlertMessageType,
} from '../../utils/getAlertErrorMessage'
import { addAlert } from '../../redux/slices/config/config'
import { sendGoogleEvent } from '../../utils/sendGoogleAnalytics'
import validateApolloErrorCartAction from '../../utils/validateApolloErrorCartAction'
import YaloAnalytics from '../../modules/analytics'
import formatDataForAnalytics from '../../utils/formatDataForAnalytics'
import { ITrackCartEventPayload } from '../../modules/interfaces'
import { calculateRemoveFinalValue } from '../../utils/cart/calculateRemoveFinalValue'

const callbackDebounceTime = process.env.REACT_APP_CART_ACTION_DEBOUNCE_TIME
  ? +process.env.REACT_APP_CART_ACTION_DEBOUNCE_TIME
  : 500

export const useDeleteItemFromCart = () => {
  const getCartAction = useGetCart()
  const dispatch = useAppDispatch()
  const abortControllers = useRef<Record<string, AbortController>>({})
  const state = useAppSelector((stateRedux) => stateRedux)
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const freegoodsAsSubitem = useAppSelector(
    (state) => !!state.defaultSlice.config?.options?.freegoodsAsSubitem,
  )

  const autoAdd = useAppSelector(
    (state) =>
      !!state.defaultSlice.sessionData?.configuration?.promotions?.autoAdd,
  )

  const {
    defaultSlice: { sessionId = '', storeName = '', sessionData },
    cartSlice: cart,
  } = state
  const { config } = state.defaultSlice
  const category = 'cart'
  let action = 'Remove from cart'

  const onCompleted = (data: IProductCart) => {
    if (data.cartRemoveProduct) {
      onCartResponse({
        data: data.cartRemoveProduct,
        state,
        dispatch,
      })
    }
  }

  const onError = (error: ApolloError) => {
    const alertType = validateApolloErrorCartAction(
      error,
      AlertMessageType.RemoveItem,
    )
    if (alertType) {
      const message = getAlertErrorMessage(alertType, config?.texts)
      dispatch(addAlert({ message }))
      getCartAction()
      const context = generateDataDogContext({
        title: 'Remove product mutation error',
        extraInfo: { function: 'useDeleteItemFromCart > onError' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(error, context)
    }
  }

  const [removeProduct] = useCartRemoveProduct({ onCompleted, onError })

  const execRemoveCartMutation = (
    quantity: number,
    sku: string,
    product: IProduct,
    currency: string,
    referrer?: Referrers,
    subItems?: SubItems,
    replace?: boolean,
    packageName?: string,
  ) => {
    const controller = new window.AbortController()
    abortControllers.current[sku] = controller
    if (referrer) {
      action = `Remove from cart from ${referrer}`
    }
    Hotjar.event(action)
    sendGoogleEvent(category, action, sku, quantity)
    window.fbq('trackCustom', 'RemoveFromCart', { sku, quantity })
    const analyticsData: ITrackCartEventPayload = formatDataForAnalytics(
      location.pathname,
      quantity,
      cart,
      product,
      currency,
      sessionData?.customer?.phoneNumber || [''],
      sessionId,
      'removeFromCart',
      storeName,
      sessionData?.extraIds,
    )
    YaloAnalytics.trackCartEvent(analyticsData)
    onRemoveProduct({
      removeProductFunction: removeProduct,
      sku,
      quantity,
      sessionId,
      storeName,
      referrer,
      signal: controller.signal,
      dispatch,
      subItems,
      replace,
      packageName,
    })
  }

  const callBackRemoveProductToCart = useCallback(
    debounceById(
      execRemoveCartMutation as IGenericFunction,
      callbackDebounceTime,
    ),
    [],
  )

  const removeItemAction = ({
    product,
    currency,
    referrer: referrerFromArgs,
    ...restArgs
  }: {
    product: IProduct
    value: number
    newValue: number
    currency: string
    referrer?: Referrers
    isBundle?: boolean
    newSubItems?: SubItem
    currentSubItems?: SubItems
    isPackage?: boolean
    isFromCounterButtons?: boolean
  }) => {
    try {
      const referrer = searchParams.get('referrer') || referrerFromArgs
      const {
        package: productPackage,
        sku,
        name,
        minQtyAllowed,
        promotionalQty,
      } = product
      const packageNameFromCart = productPackage?.name || null
      const newValueResult = calculateRemoveFinalValue({
        packageNameFromCart,
        name,
        minQtyAllowed,
        promotionalQty,
        freegoodsAsSubitem,
        autoAdd,
        ...restArgs,
      })
      if (newValueResult) {
        const { handledValue, cleanedSubItems, replaceCart, packageName } =
          newValueResult
        abortControllers.current[sku]?.abort()
        callBackRemoveProductToCart(
          sku,
          handledValue,
          sku,
          product,
          currency,
          referrer,
          cleanedSubItems,
          replaceCart,
          packageName || undefined,
        )
      }
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not remove product from cart',
        extraInfo: {
          product,
          value: restArgs.value,
          newValue: restArgs.newValue,
          function: 'removeItemAction',
        },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    }
  }
  return { removeItemAction, execRemoveCartMutation }
}

export default useDeleteItemFromCart
