import { ActivePromotion } from '../../graphQL/commons'
import { IPromotion, IPromotionUiCatalog, IConfig } from '../../interfaces'
import {
  getItemExtraInfo,
  getPromotionFields,
} from '../common/getPromotionFields'
import formatPromotionMessage, {
  IFormatPromotionMessage,
} from '../formatPromotionMessage'
import formatPercentage from '../formatPercentage'
/**
 * Function declaration to get combo promotions ui details to be shown on catalog
 *
 * @internal
 * @param product - The product to be updated
 * @param categories - The categories
 * @returns IProduct updated with promotion ui details
 */
export const getComboPromotionUiDetails = ({
  promotion,
  config,
  activePromotion,
}: {
  promotion: IPromotion
  config?: IConfig
  activePromotion?: ActivePromotion
}): IPromotionUiCatalog | undefined => {
  if (
    !config?.texts.promotions &&
    (!promotion.name || !promotion.description)
  ) {
    return
  }

  const {
    comboDefaultLabel = '',
    comboDifferentDiscountLabel = '',
    comboDifferentPercentageDiscountLabel = '',
    comboDefaultDescription = '',
    comboGetsDiferentProductDescription = '',
    comboPercentageDiscountDescription = '',
    defaultLimitLabel = '',
    defaultSaving = '',
    defaultPromotionLabel = '',
  } = config?.texts.promotions || {}

  const {
    customerBuysQuantity,
    customerBuysItems,
    customerGetsPercentage,
    customerGetsQuantity,
    customerGetsItems,
    itemsExtra,
    limitQuantity,
  } = getPromotionFields(promotion)

  const isSameProduct =
    customerBuysItems?.length === 1 &&
    customerGetsItems?.length === 1 &&
    customerBuysItems?.[0] === customerGetsItems[0]

  const discountIsFullProduct = isSameProduct || !customerGetsPercentage

  const [itemExtraExists, itemExtra] = getItemExtraInfo(itemsExtra)

  if (!itemExtraExists) {
    return {
      label: promotion?.name || defaultPromotionLabel,
      description: promotion?.description || '',
    }
  }

  const {
    price: itemExtraPrice,
    name: productDescription,
    imageURL: productImage,
  } = itemExtra

  const unitSaving = discountIsFullProduct
    ? itemExtraPrice
    : Number(itemExtraPrice) * Number(customerGetsPercentage)

  const initSaving = unitSaving * (customerGetsQuantity || 1)

  const { count: countActivePromotion = 0 } = activePromotion || {}

  const saving = initSaving * (countActivePromotion || 1)
  const promotionPercentageFormat = config?.options?.promotionPercentageFormat

  const discount = discountIsFullProduct
    ? String((customerGetsQuantity || 0) + (customerBuysQuantity || 0))
    : formatPercentage(customerGetsPercentage, promotionPercentageFormat)

  let label: string = ''
  let description: string = ''

  const formatArgs: IFormatPromotionMessage = {
    message: '',
    promotion,
    config,
    saving,
    unitSaving,
    initSaving,
    placeholders: {
      discount: String(discount),
      value: String(customerBuysQuantity),
      product: productDescription,
      quantity: String(customerGetsQuantity),
    },
  }

  const limit = limitQuantity
    ? formatPromotionMessage({
        ...formatArgs,
        limitQuantity,
        message: defaultLimitLabel as string,
      })
    : ''

  const savingLabel = formatPromotionMessage({
    ...formatArgs,
    message: defaultSaving as string,
  })

  if (isSameProduct) {
    const promotionLabelMessage = promotion.name || comboDefaultLabel
    const promotionDescriptionMessage =
      promotion.description || comboDefaultDescription
    label = formatPromotionMessage({
      ...formatArgs,
      message: promotionLabelMessage as string,
    })
    description = formatPromotionMessage({
      ...formatArgs,
      message: promotionDescriptionMessage as string,
    })
  } else {
    const defaultLabel = discountIsFullProduct
      ? (comboDifferentDiscountLabel as string)
      : (comboDifferentPercentageDiscountLabel as string)
    const defaultDescription = discountIsFullProduct
      ? (comboGetsDiferentProductDescription as string)
      : (comboPercentageDiscountDescription as string)
    const promotionLabelMessage = promotion.name || defaultLabel
    const promotionDescriptionMessage =
      promotion.description || defaultDescription
    label = formatPromotionMessage({
      ...formatArgs,
      message: promotionLabelMessage,
    })
    description = formatPromotionMessage({
      ...formatArgs,
      message: promotionDescriptionMessage,
    })
  }

  return {
    data: promotion,
    showButton: true,
    title: defaultPromotionLabel,
    label,
    description,
    limit,
    saving: promotion.name ? '' : savingLabel,
    image: productImage,
  }
}
