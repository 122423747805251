import { IPackage } from '../graphQL/commons'
import { IConfig, IPackageProduct } from '../interfaces'
import formatPrice from './formatPrice'

export const sanitizeExtraPackages = (
  source: IPackage[],
  config?: IConfig,
): IPackageProduct[] => {
  return source.map((packageSource) => {
    const currency: string | undefined =
      config?.options?.currency || config?.options?.currencySymbol
    const format: string | undefined = config?.options?.format
    return {
      ...packageSource,
      suffix: formatPrice({
        currency,
        format,
        price: packageSource.price,
      }),
    }
  })
}
