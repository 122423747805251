import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import getSummaryQuery from './query'
import { IGetSummary, IGetSummaryVariables } from './types'

/**
 * Hook declaration to obtain a get summary lazy query.
 * @param options: LazyQueryHookOptions
 * @internal
 */
export const useLazyGetSummary = (
  options?: LazyQueryHookOptions<IGetSummary, IGetSummaryVariables>,
) => {
  return useLazyQuery<IGetSummary, IGetSummaryVariables>(getSummaryQuery, {
    fetchPolicy: 'no-cache',
    ...options,
  })
}

export default useLazyGetSummary
