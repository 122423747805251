import { SchemaBatch } from '../interfaces'

export const pushData = async (data: SchemaBatch, url: string) => {
  await fetch(url, {
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export const sendBeacon = (data: SchemaBatch, url: string) =>
  navigator.sendBeacon(url, JSON.stringify(data))
