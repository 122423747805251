import { IProductCatalog } from '../graphQL/commons'
import {
  ICatalogItem,
  IConditionAction,
  IConfig,
  IProduct,
} from '../interfaces'
import { ICatalogItem as ICatalogItemHeadless } from '../graphQL/queries/getCatalog/types'
import { sanitizeBundleExtra } from './sanitizeBundleExtra'
import { calculateOutOfStock } from './calculateOutOfStock'
import { sanitizeExtraPackages } from './sanitizeExtraPackages'

const productTransformers: IConditionAction<
  { source: IProductCatalog; result: IProduct; config?: IConfig },
  IProduct
>[] = [
  {
    condition: ({ source: { outOfStock } }) => {
      return outOfStock
    },
    transformAction: ({ result, source }) => ({
      ...result,
      outOfStock: calculateOutOfStock(source),
    }),
  },
  {
    condition: ({ source }) => !!source.jumpQty,
    transformAction: ({ result, source: { jumpQty } }) => ({
      ...result,
      step: jumpQty!,
    }),
  },
  {
    condition: ({ source }) => !!source.bundlesExtra,
    transformAction: ({
      result,
      source: { bundlesExtra, bundle },
      config,
    }) => ({
      ...result,
      bundlesExtra: bundlesExtra!.map((bundleExtra) =>
        sanitizeBundleExtra({
          bundleConfig: bundle,
          bundleExtra,
          config,
        }),
      ),
    }),
  },
  {
    condition: ({ source: { extraPackages } }) => !!extraPackages?.length,
    transformAction: ({ result, source: { extraPackages }, config }) => ({
      ...result,
      extraPackages: sanitizeExtraPackages(extraPackages!, config),
    }),
  },
]

export const castIProduct = (
  source: IProductCatalog,
  config?: IConfig,
): IProduct => {
  const { bundlesExtra, jumpQty, ...restSource } = source

  return productTransformers.reduce<IProduct>(
    (acc, { condition, transformAction }) => {
      const argsTransformers = { source, result: acc, config }
      return condition(argsTransformers)
        ? transformAction(argsTransformers)
        : acc
    },
    {
      ...restSource,
      subtotal: 0,
      quantity: 0,
      promotionalQty: 0,
      minQtyAllowed: 0,
      bundlesExtra: [],
      extraPackages: [],
    },
  )
}
export const castIProducts = (source: IProductCatalog[], config?: IConfig) =>
  source.map((product) => castIProduct(product, config))

export const castICatalogItems = (
  source: ICatalogItemHeadless[],
  config?: IConfig,
): ICatalogItem[] =>
  source.map(({ productsPrices, parentId, ...item }) => ({
    ...item,
    parentId: parentId || undefined,
    productsPrices: castIProducts(productsPrices || [], config),
  }))
