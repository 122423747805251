import {
  SubItems,
  SubItem,
} from '@engyalo/delivery-ui-components/lib/interfaces/catalog'
import { IAddCartProductArgs, TBundle } from '../../interfaces'
import { isSubItem } from '../../utils/isSubItem'
import addPromotionalQtyToValue from './addPromotionalQtyToValue'

export interface ICalculateAddFinalValue
  extends Pick<
    IAddCartProductArgs,
    | 'value'
    | 'newValue'
    | 'isBundle'
    | 'newSubItems'
    | 'currentSubItems'
    | 'currentRoute'
    | 'resultFixed'
    | 'isPackage'
    | 'isFromCounterButtons'
  > {
  subItemEdit?: SubItem | null
  name: string
  packageNameFromCart: string | null
  minQtyAllowed?: number
  autoAdd: boolean
  freegoodsAsSubitem: boolean
  promotionalQty: number
}

export interface ICalculateAddFinalValueResult {
  handledValue: number
  cleanedSubItems: Array<{
    quantity: number
    items: Record<string, number>
  }>
  replaceCart: boolean
  packageName: string | null
  showAlert: boolean
}

type ValidationsCondition = (
  isFromCatalog: boolean,
  isBundle: boolean,
  hasEditSubItem: boolean,
  subItem: SubItem | TBundle | undefined,
) => boolean

type ValidationAction = (
  currentSubItems: SubItems,
  newSubItems: SubItem | TBundle,
  newValue: number,
  previousSubItem?: SubItem | null,
) => SubItems

type ValidateActions = [
  condition: ValidationsCondition,
  action: ValidationAction,
][]

const isCatalogSubItem = (
  isFromCatalog: boolean,
  isBundle: boolean,
  hasEditSubItem: boolean,
  subItem: SubItem | TBundle | undefined,
) => isFromCatalog && isBundle && !hasEditSubItem && !isSubItem(subItem)

const isCartSubItem = (
  isFromCatalog: boolean,
  isBundle: boolean,
  hasEditSubItem: boolean,
  subItem: SubItem | TBundle | undefined,
) => !isFromCatalog && isBundle && !hasEditSubItem && isSubItem(subItem)

const isEditSubItem = (
  isFromCatalog: boolean,
  isBundle: boolean,
  hasEditSubItem: boolean,
  subItem: SubItem | TBundle | undefined,
) => !isFromCatalog && isBundle && hasEditSubItem && !isSubItem(subItem)

const handleCatalogSubItem = (
  currentSubItems: SubItems,
  newSubItems: SubItem | TBundle,
  newValue: number,
) => {
  if (isSubItem(newSubItems)) {
    return []
  }
  const handledSubItems = currentSubItems || []
  handledSubItems.push({
    items: {
      ...newSubItems,
    },
    quantity: newValue,
  })
  return handledSubItems
}

const handleCartSubItem = (
  currentSubItems: SubItems,
  newSubItems: SubItem | TBundle,
  newValue: number,
) => {
  if (!isSubItem(newSubItems)) {
    return []
  }
  const handledSubItems = currentSubItems || []
  const hasPreviousSubItems = !!handledSubItems.length
  const indexOfBundle = hasPreviousSubItems
    ? handledSubItems?.findIndex((item) => {
        return item.hash === newSubItems.hash
      })
    : 0
  handledSubItems[indexOfBundle] = {
    ...newSubItems,
    items: {
      ...newSubItems.items,
    },
    quantity: newValue,
  }
  return handledSubItems
}

const handleEditSubItem = (
  currentSubItems: SubItems,
  newSubItems: SubItem | TBundle,
  newValue: number,
  previousSubItem?: SubItem | null,
) => {
  if (!previousSubItem) {
    return []
  }
  if (isSubItem(newSubItems)) {
    return []
  }
  const handledSubItems = currentSubItems || []
  const indexOfBundle = handledSubItems?.findIndex((item) => {
    return item.hash === previousSubItem.hash
  })
  handledSubItems[indexOfBundle] = {
    ...previousSubItem,
    items: {
      ...newSubItems,
    },
    quantity: newValue,
  }
  return handledSubItems
}

const validations: ValidateActions = [
  [isCatalogSubItem, handleCatalogSubItem],
  [isCartSubItem, handleCartSubItem],
  [isEditSubItem, handleEditSubItem],
]

export const calculateAddFinalValueProduct = ({
  resultFixed,
  newValue,
  minQtyAllowed = 0,
  autoAdd,
  freegoodsAsSubitem,
  promotionalQty,
  isFromCounterButtons,
}: ICalculateAddFinalValue): ICalculateAddFinalValueResult => {
  const valueWithPromotionalQty = addPromotionalQtyToValue({
    valueToUpdate: newValue,
    promotionalQty,
    freegoodsAsSubitem,
    autoAdd,
  })

  const finalValue = isFromCounterButtons ? valueWithPromotionalQty : newValue

  return {
    showAlert: !!resultFixed,
    handledValue: finalValue - minQtyAllowed,
    cleanedSubItems: [],
    replaceCart: true,
    packageName: null,
  }
}

export const calculateAddFinalValueSubitems = ({
  currentRoute,
  subItemEdit,
  isBundle,
  newSubItems,
  currentSubItems,
  newValue,
  resultFixed,
}: ICalculateAddFinalValue): ICalculateAddFinalValueResult => {
  const isFromCatalog = currentRoute === 'catalog' && !subItemEdit
  const hasEditSubItem = !!subItemEdit && isSubItem(subItemEdit)
  const replaceCart = !isFromCatalog
  let handledSubItems: SubItems = currentSubItems || []
  const validation = validations.find(([condition]) =>
    condition(isFromCatalog, isBundle || false, hasEditSubItem, newSubItems),
  )
  if (validation) {
    const [, action] = validation
    handledSubItems = action(
      handledSubItems,
      newSubItems || {},
      newValue,
      subItemEdit,
    )
  }
  const handledValue = handledSubItems.reduce(
    (acc, item) => acc + item.quantity,
    0,
  )
  const cleanedSubItems = handledSubItems?.map(
    ({ hash, ...subItemValue }) => subItemValue,
  )
  return {
    showAlert: !!resultFixed,
    handledValue,
    cleanedSubItems,
    replaceCart,
    packageName: null,
  }
}
export const calculateAddFinalValuePackage = ({
  resultFixed,
  newValue,
  minQtyAllowed = 0,
  isPackage,
  packageNameFromCart,
  name,
}: ICalculateAddFinalValue): ICalculateAddFinalValueResult => ({
  showAlert: !!resultFixed,
  handledValue: newValue - minQtyAllowed,
  cleanedSubItems: [],
  replaceCart: true,
  packageName: isPackage ? name : packageNameFromCart,
})

export const calculateAddFinalValue = (
  args: ICalculateAddFinalValue,
): ICalculateAddFinalValueResult | undefined => {
  const {
    value,
    newValue,
    isBundle,
    resultFixed,
    isPackage,
    packageNameFromCart,
  } = args
  if (value !== newValue || isBundle || resultFixed) {
    if (!isBundle && !isPackage && !packageNameFromCart) {
      return calculateAddFinalValueProduct(args)
    }
    if (isPackage || !!packageNameFromCart) {
      return calculateAddFinalValuePackage(args)
    }
    if (isBundle) {
      return calculateAddFinalValueSubitems(args)
    }
  }
}
