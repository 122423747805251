interface ICatalog {
  url: string
}

interface IApiConfig {
  basicConfig: {
    url?: string
  }
  catalog: ICatalog
}

const environment = process.env.REACT_APP_VERCEL_ENV!

const previewEnvs = {
  basicConfig: {
    url: `${process.env.REACT_APP_VERCEL_PREVIEW_URL}/`,
  },
  catalog: {
    url: `${process.env.REACT_APP_VERCEL_PREVIEW_URL}/catalog`,
  },
}

const localEnvs = {
  basicConfig: {},
  catalog: {
    url: process.env.REACT_APP_API_CATALOG_URL!,
  },
}

const prodEnvs = {
  basicConfig: {
    url: process.env.REACT_APP_API_URL!,
  },
  catalog: {
    url: process.env.REACT_APP_API_CATALOG_URL!,
  },
}

const environments: { [key: string]: IApiConfig } = {
  preview: previewEnvs,
  production: prodEnvs,
  development: localEnvs,
  localhost: localEnvs,
}

const currentEnvConfig = environments[environment || 'localhost']

export default currentEnvConfig
