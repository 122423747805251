import { ICartProduct, ITransformCartProductsParams } from '../../interfaces'
import { calculateItemPriceTotal } from '../utilsForCartReducer'

export const setItemPriceTotal = ({
  cartProducts,
}: ITransformCartProductsParams): ICartProduct[] => {
  return cartProducts.map((product) => ({
    ...product,
    priceTotal: calculateItemPriceTotal(product),
  }))
}
