import { useEffect } from 'react'
import loadViews from '../../utils/lazyLoader'

export const useLoadViews = ({
  headerComponents,
  setHeaderComponentsList,
  bodyComponents,
  setBodyComponentsList,
  footerComponents,
  excludedUiComponents = [],
  setFooterComponentsList,
  containerUid,
}: {
  headerComponents: any
  setHeaderComponentsList: any
  bodyComponents: any
  setBodyComponentsList: any
  footerComponents: any
  excludedUiComponents?: any
  setFooterComponentsList: any
  containerUid: string
}) => {
  useEffect(() => {
    if (headerComponents && headerComponents.length > 0) {
      loadViews(headerComponents, setHeaderComponentsList)
    }

    if (bodyComponents && bodyComponents.length > 0) {
      loadViews(bodyComponents, setBodyComponentsList)
    }

    if (footerComponents && footerComponents.length > 0) {
      loadViews(
        footerComponents.filter(
          (c: any) => !excludedUiComponents.includes(c.uid),
        ),
        setFooterComponentsList,
      )
    }

    return () => {
      setHeaderComponentsList([])
      setBodyComponentsList([])
      setFooterComponentsList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerUid])
}
