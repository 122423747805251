import { datadogRum } from '@datadog/browser-rum'
import { ApolloError } from '@apollo/client'
import { useLazyGetCart } from '../../graphQL/queries/getCart'
import useAppDispatch from '../useAppDispatch'
import { addAlert, manageErrors } from '../../redux/slices/config'
import { ErrorMessageTranslation } from '../../interfaces'
import { setSynchronizingCart } from '../../redux/slices/catalog'
import { onCartResponse } from '../../utils/onCartResponse'
import useAppSelector from '../useAppSelector'
import { generateDataDogContext } from '../../utils/dataDog'
import { IGetCart } from '../../graphQL/queries/getCart/types'
import getAlertErrorMessage, {
  AlertMessageType,
} from '../../utils/getAlertErrorMessage'

export const useGetCart = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector((stateRedux) => stateRedux)
  const { config } = state.defaultSlice

  const onCompleted = (data: IGetCart) => {
    onCartResponse({ data: data.cart, state, dispatch })
  }
  const onError = (error: ApolloError) => {
    const message = getAlertErrorMessage(
      error.networkError ? AlertMessageType.Network : AlertMessageType.GetCart,
      config?.texts,
    )
    dispatch(addAlert({ message }))
    dispatch(
      manageErrors({
        errorMessageTranslation: ErrorMessageTranslation.GetCart,
      }),
    )
    const context = generateDataDogContext({
      title: 'get cart query throws an exception',
      extraInfo: { function: 'getCartAction' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }
  const [getCartFn] = useLazyGetCart({
    onCompleted,
    onError,
  })

  const getCartAction = async () => {
    try {
      dispatch(setSynchronizingCart(true))
      await getCartFn({
        variables: {
          cartSessionUid: state.defaultSlice.sessionId || '',
          cartStorefrontName: state.defaultSlice.storeName || '',
        },
      })
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not get cart',
        extraInfo: { function: 'getCartAction' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    } finally {
      dispatch(setSynchronizingCart(false))
    }
  }
  return getCartAction
}

export default useGetCart
