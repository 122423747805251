import { PackageType } from '../consts/defaultConfigValues/defaultConstants'
import {
  SessionDataArgs,
  CheckoutRulesFields,
} from '../graphQL/queries/getSession/types'
import {
  ICartProduct,
  ICatalogItem,
  IProduct,
  IAlertsStatus,
  AlertConstrains,
  CartQuantities,
} from '../interfaces'
import { validateMinValues, validateMaxValues } from './validations'

export interface CategoriesCurrentProduct {
  currentProduct: IProduct | null
  currentProductMutated: IProduct | null
  categories: ICatalogItem[]
}

export const getIProductFromCategories = (
  categories: ICatalogItem[],
  sku: string,
  newQuantity: number,
): CategoriesCurrentProduct => {
  let currentProduct: IProduct | null = null
  let currentProductMutated: IProduct | null = null
  const categoriesResult: ICatalogItem[] = categories.map(
    (category: ICatalogItem) => {
      return {
        category: category.category,
        productsPrices: category.productsPrices
          ? category.productsPrices.map((item) => {
              if (item.sku === sku) {
                currentProduct = { ...item }
                currentProductMutated = { ...item, quantity: newQuantity }
                return {
                  ...item,
                  quantity: newQuantity,
                }
              }
              return {
                ...item,
              }
            })
          : [],
      }
    },
  )

  return {
    currentProduct,
    categories: categoriesResult,
    currentProductMutated,
  }
}

export const getQtyPerPackageType = (
  quantity: number,
  packageType?: string | null,
): number => (packageType === PackageType.HALFBOX ? quantity / 2 : quantity)

export const getItemsInCart = (items: ICartProduct[]): number => {
  const cartItems = items.reduce(
    (acc, { inCartQuantity, packageType }) =>
      acc + getQtyPerPackageType(inCartQuantity || 0, packageType),
    0,
  )
  return cartItems
}

type IAlertsStatusList = Array<{ key: string; result: boolean }>
type IndexConstrain = keyof typeof AlertConstrains

const alertGenerator = (alerts: IAlertsStatusList) => {
  let newAlerts: IAlertsStatus = {
    [AlertConstrains.cartConditionPendingMinAmount]: {
      isActive: false,
    },
    [AlertConstrains.cartConditionPendingMinQty]: {
      isActive: false,
    },
    [AlertConstrains.cartConditionPendingMaxAmount]: {
      isActive: false,
    },
    [AlertConstrains.cartConditionPendingMaxQty]: {
      isActive: false,
    },
    [AlertConstrains.creditLimitMaxAmount]: {
      isActive: false,
    },
  }
  alerts.forEach((val: { key: string; result: boolean }) => {
    newAlerts = {
      ...newAlerts,
      [val.key as IndexConstrain]: {
        isActive: val.result,
      },
    }
  })
  return newAlerts
}

export const getDisablePlaceOrder = (
  totalAmount: number,
  itemsTotal: number,
  callback: (alerts: IAlertsStatus) => void,
  sessionData?: SessionDataArgs,
) => {
  let disabled: boolean
  const checkoutRules = sessionData?.configuration?.checkoutRules
  disabled = !(totalAmount > 0)

  type ChekoutruleIndex = keyof typeof checkoutRules
  const checkoutList = Object.keys(checkoutRules || {})
  type CurrentValue = CheckoutRulesFields
  const alertsStatus: IAlertsStatusList = []
  if (checkoutRules) {
    checkoutList.forEach((rule) => {
      const current: CurrentValue = checkoutRules[rule as ChekoutruleIndex]
      if (current.isActive) {
        switch (rule) {
          case AlertConstrains.cartConditionPendingMinAmount: {
            const result = !validateMinValues(totalAmount, current.quantity)
            disabled = disabled === false ? result : disabled
            alertsStatus.push({
              key: AlertConstrains.cartConditionPendingMinAmount,
              result,
            })
            break
          }
          case AlertConstrains.cartConditionPendingMinQty: {
            const result = !validateMinValues(itemsTotal, current.quantity)
            disabled = disabled === false ? result : disabled
            alertsStatus.push({
              key: AlertConstrains.cartConditionPendingMinQty,
              result,
            })
            break
          }
          case AlertConstrains.cartConditionPendingMaxAmount: {
            const result = !validateMaxValues(totalAmount, current.quantity)
            disabled = disabled === false ? result : disabled
            alertsStatus.push({
              key: AlertConstrains.cartConditionPendingMaxAmount,
              result,
            })
            break
          }
          case AlertConstrains.cartConditionPendingMaxQty: {
            const result = !validateMaxValues(itemsTotal, current.quantity)
            disabled = disabled === false ? result : disabled
            alertsStatus.push({
              key: AlertConstrains.cartConditionPendingMaxQty,
              result,
            })
            break
          }
          default:
            break
        }
      }
    })
  }
  callback(alertGenerator(alertsStatus))
  return disabled
}

export const parseItemsToCartQuantities = ({
  items,
}: {
  items: ICartProduct[]
}): CartQuantities => {
  return items.reduce((itemsStore, { sku, quantity }) => {
    return {
      ...itemsStore,
      [sku]: quantity,
    }
  }, {})
}

/**
 * Calculate items quantity and total price
 * @param items - The cart items
 * @returns Object with quantity and total price
 */
export const calculateCartQuantities = (items: ICartProduct[]) => {
  let quantity = 0
  const total = items.reduce((acc, item) => {
    quantity += item.quantity
    acc += item.quantity * item.price
    return acc
  }, 0)
  return { quantity, total }
}
/**
 * Calculate item price total
 * @param item - The cart item
 * @returns number with priceTotal for one item on the cart
 */
export const calculateItemPriceTotal = (item: ICartProduct) =>
  item.price * item.quantity - (item.discounts ?? 0)
