import { IPromotion, PromotionType, ComplexPromotionTypes } from '../../graphQL/commons'
import { getPromotionFields } from '../../utils/common/getPromotionFields'
import { Referrers } from '../../consts/defaultConfigValues/defaultConstants'
import useAppSelector from '../useAppSelector'
import {
  IAddPrefillCartItem,
  useAddPrefillCart,
} from '../prefillCart/useAddPrefillCart'
import {
  ICommonPromotionFields,
  IConditionAction,
  IProduct,
} from '../../interfaces'

interface ITransformerItemsArgs {
  currentItems: IAddPrefillCartItem[]
  autoAdd: boolean
  promotionsFields: ICommonPromotionFields
  selectedProduct?: IProduct
}

const transformersItems: IConditionAction<
  ITransformerItemsArgs,
  IAddPrefillCartItem[]
>[] = [
  {
    condition: ({ 
      promotionsFields: { type },
      selectedProduct
    }) => type === PromotionType.COMBO && !!selectedProduct,
    transformAction: ({
      selectedProduct,
      promotionsFields: { customerBuysQuantity: quantity },
      currentItems,
    }) => [
      ...currentItems,
      {
        sku: selectedProduct!.sku,
        quantity,
        package: null,
      },
    ],
  },
  {
    condition: ({
      promotionsFields: { type, customerGetsItems, customerGetsQuantity },
      autoAdd,
    }) => type === PromotionType.COMBO && !autoAdd && !!customerGetsItems.length && !!customerGetsQuantity,
    transformAction: ({
      promotionsFields: {
        customerGetsQuantity: quantity,
        customerGetsItems: [sku],
      },
      currentItems,
    }) => [
      ...currentItems,
      {
        sku,
        quantity,
        package: null,
      },
    ],
  },
  {
    condition: ({
      promotionsFields: {
        type,
        customerGetsItems,
        customerGetsQuantity,
        customerGetsPercentage,
      },
      autoAdd,
    }) =>
      type === PromotionType.COMBO &&
      !!autoAdd && 
      !!customerGetsQuantity &&
      !!customerGetsItems.length &&
      !!customerGetsPercentage,
    transformAction: ({
      promotionsFields: {
        customerGetsItems: [sku],
        customerGetsQuantity,
      },
      currentItems,
    }) => [
      ...currentItems,
      {
        sku,
        quantity: customerGetsQuantity,
        package: null,
      },
    ],
  },
  {
    condition: ({
      promotionsFields: { type, customerBuysGroups, customerGetsGroups },
    }) => type === PromotionType.COMPLEX && customerBuysGroups.length === 1 && customerGetsGroups.length === 1,
    transformAction: ({
      promotionsFields: { customerBuysGroups },
      currentItems,
    }) => {
      return [
        ...currentItems,
        // Only support one customerBuys group and one customerGets group
        ...customerBuysGroups[0].items
          .filter(({ sku }) => sku !== null)
          .map(({ sku, quantity }) => ({
            sku,
            quantity,
            package: null,
          })),
      ]},
  },

  {
    condition: ({
      promotionsFields: { type, customerBuysGroups, customerGetsGroups },
      autoAdd,
    }) => type === PromotionType.COMPLEX && customerBuysGroups.length === 1 && customerGetsGroups.length === 1 && 
     (
      (customerGetsGroups[0].type !== ComplexPromotionTypes.DIRECT_PERCENTAGE && !autoAdd) ||
      (customerGetsGroups[0].type === ComplexPromotionTypes.PERCENTAGE && !!autoAdd)),
    transformAction: ({
      promotionsFields: { customerGetsGroups },
      currentItems,
    }) => {
      return [
        ...currentItems,
        // Only support one customerBuys group and one customerGets group
        ...customerGetsGroups[0].items
          .filter(({ sku }) => sku !== null)
          .map(({ sku, quantity }) => ({
            sku,
            quantity,
            package: null,
          } as IAddPrefillCartItem)),
      ]},
  },
  {
    condition: ({ currentItems: { length } }) => length > 1,
    transformAction: ({ currentItems }) => {
      const mapQuantity = currentItems.reduce<Record<string, number>>(
        (acc, item) => {
          const { sku, quantity } = item
          return {
            ...acc,
            [sku]: (acc[sku] || 0) + quantity,
          }
        },
        {},
      )
      const result = Object.keys(mapQuantity).map<IAddPrefillCartItem>(
        (sku) => ({
          sku,
          quantity: mapQuantity[sku],
          package: null,
        }),
      )
      return result
    },
  },
]

interface IAddProductFromPromotionArgs {
  promotion: IPromotion
}
export const useAddProductFromPromotion = () => {
  const selectedProduct = useAppSelector(
    (state) => state.catalogSlice.selectedProduct,
  )
  const autoAdd = useAppSelector(
    (state) =>
      state.defaultSlice.sessionData?.configuration?.promotions?.autoAdd,
  )
  const storefrontName =
    useAppSelector((state) => state.defaultSlice.storeName) || ''
  const sessionUid =
    useAppSelector((state) => state.defaultSlice.sessionId) || ''

  const { addPrefillCart } = useAddPrefillCart()

  const addProductFromPromotion = ({
    promotion,
  }: IAddProductFromPromotionArgs) => {
    const promotionFields = getPromotionFields(promotion)
    const transformItemsArgs: ITransformerItemsArgs = {
      currentItems: [],
      autoAdd: !!autoAdd,
      promotionsFields: promotionFields,
      selectedProduct,
    }

    
    const items = transformersItems.reduce<IAddPrefillCartItem[]>(
      (acc, { condition, transformAction }) =>
        condition({ ...transformItemsArgs, currentItems: acc })
          ? transformAction({ ...transformItemsArgs, currentItems: acc })
          : acc,
      [],
    )

    addPrefillCart({
      items,
      storefrontName,
      sessionUid,
      referrer: Referrers.PROMOTION_DETAIL,
      replaceCart: false,
    })
  }
  return { addProductFromPromotion }
}

export default useAddProductFromPromotion
