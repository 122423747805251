import { useEffect, ReactNode } from 'react'
import ReactGA from 'react-ga4'
import useFetchInitialCategoriesData from '../hooks/categories/useInitialCategoriesData'
import useInitialCatalogData from '../hooks/catalog/useInitialCatalogData'
import { fetchConfig, manageErrors, setSessionId } from '../redux/slices/config'
import { useAppDispatch, useAppSelector } from '../hooks'
import { ErrorMessageTranslation } from '../interfaces'
import getUrlParams from '../utils/getUrlParams'

/**
 * This component is in charge of executing the redux loadCatalogue action, this is the wrapper
 * of all the containers,
 */
interface ConfigProps {
  children: ReactNode
}

const Config = ({ children }: ConfigProps) => {
  const slug = useAppSelector((state) => state.defaultSlice.storeName)
  const config = useAppSelector((state) => state.defaultSlice.config)

  const dispatch = useAppDispatch()
  const {
    storeName: slugFromUrl,
    sessionId,
    language,
  } = getUrlParams(window.location.pathname)

  const fetchInitialCatalogDataAction = useInitialCatalogData()
  const fetchInitialCategoriesDataAction = useFetchInitialCategoriesData()

  useEffect(() => {
    if (slug !== slugFromUrl) {
      dispatch(fetchConfig({ slug: slugFromUrl, language }))
    }
  }, [slug, slugFromUrl, dispatch])

  useEffect(() => {
    if (config) {
      dispatch(setSessionId(sessionId))
      if (config.options?.usePaginatedCategories) {
        fetchInitialCategoriesDataAction(sessionId, slugFromUrl)
      } else {
        fetchInitialCatalogDataAction(sessionId, slugFromUrl)
      }
    }
    if (!slugFromUrl || !sessionId) {
      dispatch(
        manageErrors({
          errorMessageTranslation: ErrorMessageTranslation.NonProvidedParams,
          showErrorTitle: true,
          globalError: true,
        }),
      )
    }
    // eslint-disable-next-line
  }, [config, sessionId, slugFromUrl])

  useEffect(() => {
    if (
      config &&
      (config.analytics?.googleAnalyticsId || process.env.REACT_APP_GOOGLE_ID)
    ) {
      const googleId =
        config.analytics?.googleAnalyticsId || process.env.REACT_APP_GOOGLE_ID
      ReactGA.initialize(googleId || '')
    }
    // eslint-disable-next-line
  }, [config])

  useEffect(() => {
    if (!config) {
      return
    }
    const fbPixelId =
      config.analytics?.fbPixelId || process.env.REACT_APP_FB_PIXEL_ID
    if (!fbPixelId) {
      return
    }

    window.fbq('init', fbPixelId)
  }, [config])

  return children
}

export default Config
