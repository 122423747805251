import { SubItem, SubItems } from '@engyalo/delivery-ui-components'
import { isSubItem } from '../isSubItem'
import addPromotionalQtyToValue from './addPromotionalQtyToValue'

export interface ICalculateRemoveFinalValue {
  value: number
  newValue: number
  isBundle?: boolean
  newSubItems?: SubItem
  currentSubItems?: SubItems
  isPackage?: boolean
  name: string
  packageNameFromCart: string | null
  minQtyAllowed?: number
  promotionalQty: number
  autoAdd: boolean
  freegoodsAsSubitem: boolean
  isFromCounterButtons?: boolean
}

export interface ICalculateRemoveFinalValueResult {
  handledValue: number
  cleanedSubItems: Array<{
    quantity: number
    items: Record<string, number>
  }>
  replaceCart: boolean
  packageName: string | null
}

export const calculateRemoveFinalValueProduct = ({
  newValue,
  minQtyAllowed = 0,
  promotionalQty,
  isFromCounterButtons,
  autoAdd,
  freegoodsAsSubitem,
}: ICalculateRemoveFinalValue): ICalculateRemoveFinalValueResult => {
  const valueWithPromotionalQty = addPromotionalQtyToValue({
    valueToUpdate: newValue,
    promotionalQty,
    freegoodsAsSubitem,
    autoAdd,
  })

  const finalValue = isFromCounterButtons ? valueWithPromotionalQty : newValue

  return {
    handledValue: finalValue - minQtyAllowed,
    cleanedSubItems: [],
    replaceCart: true,
    packageName: null,
  }
}

export const calculateRemoveFinalValuePackage = ({
  newValue,
  isPackage,
  name,
  packageNameFromCart,
  minQtyAllowed = 0,
}: ICalculateRemoveFinalValue): ICalculateRemoveFinalValueResult => ({
  handledValue: newValue - minQtyAllowed,
  cleanedSubItems: [],
  replaceCart: true,
  packageName: isPackage ? name : packageNameFromCart,
})

export const calculateRemoveFinalValueSubitems = ({
  newValue,
  newSubItems,
  currentSubItems,
}: ICalculateRemoveFinalValue): ICalculateRemoveFinalValueResult => {
  const handledSubItems: SubItems = currentSubItems || []
  if (newSubItems && isSubItem(newSubItems)) {
    const hasPreviousSubItems = !!handledSubItems.length
    const indexOfBundle = hasPreviousSubItems
      ? handledSubItems?.findIndex((item) => item.hash === newSubItems.hash)
      : 0
    if (newValue === 0) {
      handledSubItems.splice(indexOfBundle, 1)
    } else {
      handledSubItems[indexOfBundle] = {
        ...newSubItems,
        items: {
          ...newSubItems.items,
        },
        quantity: newValue,
      }
    }
  }
  const cleanedSubItems = handledSubItems.map(
    ({ hash, ...subItemValue }) => subItemValue,
  )
  const handledValue = handledSubItems.reduce(
    (acc, item) => acc + item.quantity,
    0,
  )
  return {
    handledValue,
    cleanedSubItems,
    replaceCart: true,
    packageName: null,
  }
}

export const calculateRemoveFinalValue = (
  args: ICalculateRemoveFinalValue,
): ICalculateRemoveFinalValueResult | undefined => {
  const { value, newValue, isBundle, isPackage, packageNameFromCart } = args
  if (newValue !== value || isBundle || newValue === 0) {
    if (!isBundle && !isPackage && !packageNameFromCart) {
      return calculateRemoveFinalValueProduct(args)
    }
    if (isPackage || !!packageNameFromCart) {
      return calculateRemoveFinalValuePackage(args)
    }
    if (isBundle) {
      return calculateRemoveFinalValueSubitems(args)
    }
  }
}
