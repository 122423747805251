const sortByKey: <T>(array: T[], key: keyof T, reverse?: boolean) => T[] = (
  array,
  key,
  reverse = false,
) =>
  Array.from(array).sort((a, b) => {
    const firstElement = reverse ? b[key] : a[key]
    const secondElement = reverse ? a[key] : b[key]

    if (firstElement < secondElement) {
      return -1
    }
    if (firstElement > secondElement) {
      return 1
    }
    return 0
  })

export default sortByKey
