import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../hooks'
import { updateParams } from '../redux/slices/config'
import { getQueryParams } from '../utils/getURLData'

const RouterParams = () => {
  const dispatch = useAppDispatch()
  const routerParams = useParams()
  const queryParams = getQueryParams()

  useEffect(() => {
    dispatch(
      updateParams({
        ...routerParams,
        ...queryParams,
        location: {
          pathname: window.location.pathname,
        },
      }),
    )
  }, [routerParams, queryParams, dispatch])
  return null
}

export default RouterParams
