import { ApolloError } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import { useParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import {
  ButtonType,
  ICategoryNavigation,
  ICategoryPagination,
  IConditionAction,
  INavigateConditionArgs,
  INavigateToCategoryArgs,
  ITransformCategoriesArgs,
} from '../../interfaces'
import {
  setCategoriesNav,
  setFetchingMore,
} from '../../redux/slices/categoriesnav'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import { useLazyGetCategories } from '../../graphQL/queries/getCategories/hook'
import {
  ICategories,
  ICategoriesItem,
  ICategoriesVariables,
} from '../../graphQL/queries/getCategories/types'
import { generateDataDogContext } from '../../utils/dataDog'
import usePushView from '../usePushView'
import {
  CATEGORIES_PAGE_SIZE,
  CATEGORIES_PAGE_SIZE_NAVIGATION,
  EMPTY_STRING,
} from '../../consts/defaultConfigValues/defaultConstants'
import {
  URL_PREFFIX_CATEGORIES_NAV,
  URL_PREFFIX_CATEGORIES_NAV_HOME,
  URL_PREFFIX_CATEGORY,
  initialPagination,
  transformerCategories,
} from './constants'

export const useCategoriesNavigation = () => {
  const dispatch = useAppDispatch()
  const { categoryId, categoryName } = useParams<{
    categoryId?: string
    categoryName?: string
  }>()
  const [pagination, setPagination] =
    useState<ICategoryPagination>(initialPagination)
  const storefrontName = useAppSelector((state) => state.defaultSlice.storeName)
  const sessionID = useAppSelector((state) => state.defaultSlice.sessionId)
  const config = useAppSelector((state) => state.defaultSlice.config)
  const existingCategories = useAppSelector(
    (state) => state.categoriesNavSlice.categoriesNav,
  )
  const isPaginatedCategories = useAppSelector(
    (state) => !!state.defaultSlice.config?.options?.usePaginatedCategories,
  )
  const pushView = usePushView()
  const pageSize = useMemo(
    () =>
      isPaginatedCategories
        ? CATEGORIES_PAGE_SIZE
        : CATEGORIES_PAGE_SIZE_NAVIGATION,
    [isPaginatedCategories],
  )

  const seeAllCatalogItem: ICategoryNavigation =
    useMemo<ICategoryNavigation>(() => {
      const seeCatalogLabel =
        config?.texts?.catalogue?.seeAllProducts || EMPTY_STRING
      return {
        name: seeCatalogLabel,
        id: categoryId || EMPTY_STRING,
        type: ButtonType.GO_CATALOG,
        slug: seeCatalogLabel,
        image: config?.options?.placeholderImage || EMPTY_STRING,
        category: EMPTY_STRING,
      }
    }, [config, categoryId])

  const seeCategoryProductsItem: ICategoryNavigation =
    useMemo<ICategoryNavigation>(() => {
      const seeCategoryProductsLabelPreffix =
        config?.texts?.catalogue?.seeAllCategoryProductsPrefix || EMPTY_STRING

      const name = `${seeCategoryProductsLabelPreffix} ${categoryName}`

      return {
        name,
        id: categoryId || EMPTY_STRING,
        type: ButtonType.GO_PRODUCTS,
        slug: name,
        image: config?.options?.placeholderImage || EMPTY_STRING,
        category: categoryName || EMPTY_STRING,
      }
    }, [categoryId, categoryName, config])

  const transformCategories = (
    categoriesResponse: ICategoriesItem[],
    existingCategories: ICategoryNavigation[] = [],
  ) => {
    return transformerCategories.reduce<ICategoryNavigation[]>(
      (acc, { condition, transformAction }) => {
        const args: ITransformCategoriesArgs = {
          existingCategories: acc,
          isPaginatedCategories,
          categoryId,
          categoriesResponse,
          seeAllCatalogItem,
          seeCategoryProductsItem,
        }
        return condition(args) ? transformAction(args) : acc
      },
      existingCategories,
    )
  }
  const onGetMoreCategoriesRequestCompleted = (data: ICategories) => {
    const { categories: categoriesResponse } = data
    const hasMore = categoriesResponse.length === CATEGORIES_PAGE_SIZE
    setPagination({
      ...pagination,
      hasMore,
      pageNumber: pagination.pageNumber + 1,
    })
    const transformedCategories = transformCategories(
      categoriesResponse,
      existingCategories,
    )
    dispatch(setCategoriesNav(transformedCategories))
    dispatch(setFetchingMore(false))
  }

  const onGetCategoriesFirstRequestCompleted = (data: ICategories) => {
    const { categories: categoriesResponse } = data
    const hasMore = categoriesResponse.length === CATEGORIES_PAGE_SIZE
    setPagination({
      ...initialPagination,
      hasMore,
      pageNumber: 1,
    })
    const transformedCategories = transformCategories(categoriesResponse)
    dispatch(setCategoriesNav(transformedCategories))
    dispatch(setFetchingMore(false))
  }

  const onErrorCategories = (error: ApolloError) => {
    const context = generateDataDogContext({
      title: 'could not fetch categories response',
      extraInfo: { function: 'fetchCategoriesResponse' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }

  const [getMoreCategories] = useLazyGetCategories({
    onCompleted: onGetMoreCategoriesRequestCompleted,
    onError: onErrorCategories,
  })

  const [getCategoriesFirstRequest] = useLazyGetCategories({
    onCompleted: onGetCategoriesFirstRequestCompleted,
    onError: onErrorCategories,
  })
  const [getCategories] = useLazyGetCategories()

  const getVariables = (
    currentPagination: ICategoryPagination,
    parentId?: string,
  ): ICategoriesVariables => {
    const { pageNumber } = currentPagination
    return {
      storefrontName,
      sessionID,
      parentId,
      pagination: {
        pageNumber,
        pageSize,
      },
    }
  }

  const navigateConditions: IConditionAction<INavigateConditionArgs, string>[] =
    [
      {
        condition: ({ item: { type } }) => type === ButtonType.GO_CATALOG,
        transformAction: ({ homePath }) => homePath,
      },
      {
        condition: ({ item: { type } }) => type === ButtonType.GO_PRODUCTS,
        transformAction: ({ homePath, item: { category } }) =>
          `${homePath}${URL_PREFFIX_CATEGORY}${category}`,
      },
      {
        condition: ({ item: { type }, categoriesResultLength }) =>
          type === ButtonType.GO_CATEGORY && categoriesResultLength === 0,
        transformAction: ({ homePath, item: { category } }) =>
          `${homePath}${URL_PREFFIX_CATEGORY}${category}`,
      },
      {
        condition: ({
          item: { type },
          categoriesResultLength,
          isCategoriesView,
        }) =>
          type === ButtonType.GO_CATEGORY &&
          categoriesResultLength > 0 &&
          !isCategoriesView,
        transformAction: ({ homePath, item: { id, category } }) =>
          `${homePath}${URL_PREFFIX_CATEGORIES_NAV}${id}/${category}`,
      },
      {
        condition: ({
          item: { type },
          categoriesResultLength,
          isCategoriesView,
        }) =>
          type === ButtonType.GO_CATEGORY &&
          categoriesResultLength > 0 &&
          isCategoriesView,
        transformAction: ({ homePath, item: { id, category } }) =>
          `${homePath}${URL_PREFFIX_CATEGORIES_NAV_HOME}${id}/${category}`,
      },
    ]

  const navigateToCategory = async ({
    item,
    item: { id },
    isCategoriesView,
  }: INavigateToCategoryArgs) => {
    try {
      dispatch(setFetchingMore(true))
      const homePath = `/${storefrontName}/${sessionID}/`
      const categoryNavigateData = await getCategories({
        variables: getVariables(initialPagination, id),
      })
      const categoriesResultLength =
        categoryNavigateData.data?.categories.length || 0

      const argsForCondition = {
        item,
        homePath,
        isCategoriesView,
        categoriesResultLength,
      }
      const result = navigateConditions.find(({ condition }) =>
        condition(argsForCondition),
      )
      const path = (await result?.transformAction(argsForCondition)) || ''
      pushView({
        path,
      })
    } finally {
      dispatch(setFetchingMore(false))
    }
  }

  const setCategoriesNavigation = async () => {
    const { hasMore } = pagination
    if (hasMore) {
      try {
        dispatch(setFetchingMore(true))
        await getMoreCategories({
          variables: getVariables(pagination, categoryId),
        })
      } finally {
        dispatch(setFetchingMore(false))
      }
    }
  }

  useEffect(() => {
    dispatch(setFetchingMore(true))
    getCategoriesFirstRequest({
      variables: getVariables(initialPagination, categoryId),
    })
  }, [categoryId, categoryName])

  return { setCategoriesNavigation, navigateToCategory }
}
