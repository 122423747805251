import { IConfig, Localization } from '../interfaces'
import globals from './defaultConfigValues/defaultGlobals'
import {
  DefaultModalContainerNames,
  DefaultCheckoutRulesNames,
  DefaultContainerNames,
} from './defaultConfigValues/defaultConstants'

const defaultConfig: IConfig = {
  name: 'Cinnamon',
  botSlug: 'cinnamon',
  defaultRoute: '/',
  phoneNumber: '',
  logo: 'https://assets.website-files.com/5cabb6794998de00739f1740/5da88393805cc15db35db31d_yalo-logo__violet.svg',
  globalErrorMessage: 'An error has occurred',
  options: {
    localization: Localization.En,
    orderMinAmount: 10,
    currencyCode: '',
    currencySymbol: '',
    currencyDisplay: '{{symbol}}{{price}}{{code}}',
    pagination: false,
    useModal: true,
    format: '#,##0.00',
    promotionPercentageFormat: '#0%',
    usePaginatedCategories: false,
    placeholderImage: '',
    cartGroupingTotals: false,
    splash: {
      logo: true,
    },
    freegoodsAsSubitem: false,
    ignoreSubtotal: false,
    hideCartRestrictions: true,
    hideSubcategories: true,
    hideSubCategoriesProducts: true,
    showSubCategoriesImages: true,
    productDetailView: false,
    trackEventsURL: null,
    paginationSize: 10,
  },
  analytics: {
    googleAnalyticsId: '',
    fbPixelId: '',
  },
  metatags: {
    description: 'Storefront powered by Yalo',
    keywords: 'Storefront, Yalo, B2B, ecommerce, flow',
    author: 'yalo.com',
    favicon:
      'https://assets.website-files.com/5cabb6794998de00739f1740/5da88393805cc15db35db31d_yalo-logo__violet.svg',
    title: 'Cinnamon Default',
    charset: 'utf8',
    lang: 'en',
  },
  globals,
  theme: {},
  routes: [
    {
      route: '/:slug/:sessionId',
      exact: true,
      container: 'CatalogueDefault',
    },
    {
      route: '/:slug/:sessionId/categories',
      exact: true,
      container: 'CategoryListHome',
    },
    {
      route: '/:slug/:sessionId/categories/:categoryId/:categoryName',
      exact: true,
      container: 'CategoryList',
    },
    {
      route: '/:slug/:sessionId/cart',
      exact: true,
      container: 'ShoppingCart',
    },
    {
      route: '/:slug/:sessionId/filters',
      exact: true,
      container: 'FilteredProducts',
    },
    {
      route: '/:slug/:sessionId/recommended-products',
      exact: true,
      container: 'SuggestedProducts',
    },
    {
      route: '/:slug/:sessionId/meta/product/:sku',
      exact: true,
      container: DefaultContainerNames.PRODUCT_DETAIL_META,
    },
    {
      route: '/:slug/:sessionId/recommended-product/:sku',
      exact: true,
      container: DefaultContainerNames.RECOMMENDED_PRODUCT_DETAIL,
    },
    {
      route: '/:slug/:sessionId/product/:sku',
      exact: true,
      container: DefaultContainerNames.PRODUCT_DETAIL,
    },
    {
      route: '/:slug/:sessionId/summary',
      exact: true,
      container: 'CartSummary',
    },
    {
      route: '/:slug/:sessionId/categories-nav/:categoryId/:categoryName',
      exact: true,
      container: 'CategoriesNav',
    },
    {
      route: '/:slug/:sessionId/categories-nav',
      exact: true,
      container: 'CategoriesNav',
    },
    {
      route: '/:slug/:sessionId/category-nav/:categoryName',
      exact: true,
      container: 'CategoryNavDetail',
    },
  ],
  containers: {
    CatalogueDefault: {
      uid: 'cataloguedefault',
      type: 'catalog',
      title: 'Catalogue Default',
      slug: 'catalogue',
      header: {
        components: [
          {
            uid: 'cataloguedefault-header-main',
            name: 'MainHeader',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderDefault',
          },
          {
            uid: 'cataloguedefault-header-banners',
            name: 'CarouselBanners',
            importRoute: 'Carousel',
            defaultProps: 'CarouselBanners',
          },
          {
            uid: 'cataloguedefault-header-nav',
            name: 'CategoryNav',
            importRoute: 'TabsMenu',
            defaultProps: 'NavMenuDefault',
          },
          {
            uid: 'GlobalPromotionAlert',
            name: 'Global Promotion Alert',
            importRoute: 'Alert',
            defaultProps: 'AlertGlobalPromotion',
          },
          {
            uid: DefaultCheckoutRulesNames.CREDIT_LIMIT_REACHED,
            name: 'Credit Limit Reached',
            importRoute: 'Alert',
            defaultProps: 'AlertCreditLimit',
          },
        ],
      },
      body: {
        components: [
          {
            defaultProps: 'CarouselRecomendedProductsHead',
            uid: 'recomendations-header-carousel',
            importRoute: 'HeaderList',
            name: 'HeaderList',
          },
          {
            defaultProps: 'CarouselRecomendedProducts',
            uid: 'recomendations-body-carousel',
            importRoute: 'Carousel',
            name: 'Recomendations Carousel',
          },
          {
            uid: 'header-body-list',
            name: 'HeaderList',
            importRoute: 'HeaderList',
            defaultProps: 'HeaderListDefault',
          },
          {
            uid: 'cataloguedefault-body-list',
            name: 'Catalogue',
            importRoute: 'CatalogueList',
            defaultProps: 'CatalogueListDefault',
            props: {},
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'cataloguedefault-footer-main',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterDefault',
          },
        ],
      },
      modals: [
        DefaultModalContainerNames.SEARCH_PRODUCT,
        DefaultModalContainerNames.PRODUCT_DETAIL,
        DefaultModalContainerNames.PREFILL_CART,
        DefaultModalContainerNames.CHOSEN_FREEGOODS,
      ],
    },
    CategoryNavDetail: {
      uid: 'categorydetail',
      type: 'catalog',
      title: 'Category Detail',
      slug: 'category',
      header: {
        components: [
          {
            uid: 'categorydetail-header-001',
            name: 'MainHeader',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderCategoriesNav',
          },
          {
            uid: 'GlobalPromotionAlert',
            name: 'Global Promotion Alert',
            importRoute: 'Alert',
            defaultProps: 'AlertGlobalPromotion',
          },
        ],
      },
      body: {
        components: [
          {
            uid: 'header-body-list',
            name: 'HeaderList',
            importRoute: 'HeaderList',
            defaultProps: 'HeaderListDefault',
          },
          {
            uid: 'categorydetail-body-001',
            name: 'CatalogueListCategoryNav',
            importRoute: 'CatalogueList',
            defaultProps: 'CatalogueListCategoriesNav',
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'categorydetail-footer-001',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterDefault',
          },
        ],
      },
      modals: [
        DefaultModalContainerNames.SEARCH_PRODUCT,
        DefaultModalContainerNames.PRODUCT_DETAIL,
        DefaultModalContainerNames.CHOSEN_FREEGOODS,
      ],
    },
    CategoryListHome: {
      uid: 'categorylist',
      type: 'categories',
      title: 'Category List',
      slug: 'categories',
      header: {
        components: [
          {
            uid: 'categorylist-header-001',
            name: 'MainHeader',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderDefault',
          },
          {
            uid: 'cataloguedefault-header-banners',
            name: 'CarouselBanners',
            importRoute: 'Carousel',
            defaultProps: 'CarouselBanners',
          },
        ],
      },
      body: {
        components: [
          {
            defaultProps: 'CarouselRecomendedProductsHead',
            uid: 'recomendations-header-carousel',
            importRoute: 'HeaderList',
            name: 'HeaderList',
          },
          {
            defaultProps: 'CarouselRecomendedProducts',
            uid: 'recomendations-body-carousel',
            importRoute: 'Carousel',
            name: 'Recomendations Carousel',
          },
          {
            uid: 'categorylist-body-001',
            name: 'Category',
            importRoute: 'CategoryList',
            defaultProps: 'CategoryListDefault',
          },
        ],
      },
      modals: [
        DefaultModalContainerNames.SEARCH_PRODUCT,
        DefaultModalContainerNames.PRODUCT_DETAIL,
        DefaultModalContainerNames.PREFILL_CART,
        DefaultModalContainerNames.CHOSEN_FREEGOODS,
      ],
    },
    CategoryList: {
      uid: 'categorylist',
      type: 'categories',
      title: 'Category List',
      slug: 'categories',
      header: {
        components: [
          {
            uid: 'categorylist-header-001',
            name: 'MainHeader',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderWithBack',
          },
          {
            uid: 'cataloguedefault-header-banners',
            name: 'CarouselBanners',
            importRoute: 'Carousel',
            defaultProps: 'CarouselBanners',
          },
        ],
      },
      body: {
        components: [
          {
            defaultProps: 'CarouselRecomendedProductsHead',
            uid: 'recomendations-header-carousel',
            importRoute: 'HeaderList',
            name: 'HeaderList',
          },
          {
            defaultProps: 'CarouselRecomendedProducts',
            uid: 'recomendations-body-carousel',
            importRoute: 'Carousel',
            name: 'Recomendations Carousel',
          },
          {
            uid: 'categorylist-body-001',
            name: 'Category',
            importRoute: 'CategoryList',
            defaultProps: 'SubCategoryListDefault',
          },
          {
            uid: 'categorydetail-nav-body-001',
            name: 'CatalogueListCategoryNav',
            importRoute: 'CatalogueList',
            defaultProps: 'OptionalCatalogueListCategoriesNav',
          },
        ],
      },
      modals: [
        DefaultModalContainerNames.SEARCH_PRODUCT,
        DefaultModalContainerNames.PRODUCT_DETAIL,
        DefaultModalContainerNames.PREFILL_CART,
        DefaultModalContainerNames.CHOSEN_FREEGOODS,
      ],
    },
    [DefaultContainerNames.PRODUCT_DETAIL]: {
      uid: 'productdetail',
      title: 'Product detail',
      slug: 'product',
      type: 'productdetail',
      header: {
        components: [
          {
            uid: 'productdetail-header-001',
            name: 'MainHeader',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderProductDetail',
          },
        ],
      },
      body: {
        components: [
          {
            uid: 'productdetail-body-001',
            name: 'Product Detail',
            importRoute: 'ItemCard',
            defaultProps: 'ItemCardProductDetailPage',
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'productdetail-footer-001',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterProductDetail',
          },
        ],
      },
      modals: [DefaultModalContainerNames.CHOSEN_FREEGOODS],
    },
    [DefaultContainerNames.PRODUCT_DETAIL_META]: {
      uid: 'productdetail',
      title: 'Product detail',
      slug: 'product',
      header: {
        components: [
          {
            uid: 'productdetail-header-001',
            name: 'MainHeader',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderDetail',
          },
        ],
      },
      body: {
        components: [
          {
            uid: 'productdetail-body-001',
            name: 'Product Detail',
            importRoute: 'ItemCard',
            defaultProps: 'ItemCardProductDetailMetaPage',
          },
        ],
      },
    },
    [DefaultContainerNames.RECOMMENDED_PRODUCT_DETAIL]: {
      uid: 'productdetail',
      title: 'Product detail',
      slug: 'product',
      header: {
        components: [
          {
            uid: 'productdetail-header-001',
            name: 'MainHeader',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderProductDetail',
          },
        ],
      },
      body: {
        components: [
          {
            uid: 'productdetail-body-001',
            name: 'Product Detail',
            importRoute: 'ItemCard',
            defaultProps: 'ItemCardProductDetailPage',
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'productdetail-footer-001',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterRecommendedProuctDetail',
          },
        ],
      },
      modals: [DefaultModalContainerNames.CHOSEN_FREEGOODS],
    },
    ShoppingCart: {
      uid: 'shoppingcart',
      title: 'Shopping Cart',
      type: 'cart',
      slug: 'cart',
      header: {
        components: [
          {
            uid: 'shoppingcart-header-001',
            name: 'Main Header',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderCart',
          },
          {
            uid: 'GlobalPromotionAlertCart',
            name: 'Global Promotion Alert Cart',
            importRoute: 'Alert',
            defaultProps: 'AlertGlobalPromotionCart',
          },
        ],
      },
      body: {
        components: [
          {
            uid: DefaultCheckoutRulesNames.MIN_QTY_CART_CHECKOUT_RULE,
            name: 'Min quantity',
            importRoute: 'Alert',
            defaultProps: 'AlertMinQuantity',
          },
          {
            uid: DefaultCheckoutRulesNames.MAX_QTY_CART_CHECKOUT_RULE,
            name: 'Max quantity',
            importRoute: 'Alert',
            defaultProps: 'AlertMaxQuantity',
          },
          {
            uid: DefaultCheckoutRulesNames.MAX_AMOUNT_CART_CHECKOUT_RULE,
            name: 'Max amount',
            importRoute: 'Alert',
            defaultProps: 'AlertMaxAmount',
          },
          {
            uid: 'shoppingcart-body-001',
            name: 'Cart List',
            importRoute: 'CartList',
            defaultProps: 'CartListDefault',
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'shoppingcart-footer-002',
            name: 'Restrictions text',
            importRoute: 'Text',
            defaultProps: 'RestrictionsText',
          },
          {
            uid: DefaultCheckoutRulesNames.MIN_AMOUNT_CART_CHECKOUT_RULE,
            name: 'Min Amount',
            importRoute: 'MinAmount',
            defaultProps: 'MinAmountDefault',
          },
          {
            uid: 'shoppingcart-footer-001',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterCartPriceDetailed',
          },
        ],
      },
      modals: [
        DefaultModalContainerNames.RECOMMENDED_PRODUCTS,
        DefaultModalContainerNames.RECOMMENDED_PRODUCT_DETAIL,
        DefaultModalContainerNames.PRODUCT_DETAIL,
        DefaultModalContainerNames.LINKED_PRODUCTS,
        DefaultModalContainerNames.CHOSEN_FREEGOODS,
      ],
    },
    FilteredProducts: {
      uid: 'filteredproducts',
      type: 'filters',
      title: 'Filtered products',
      slug: 'filteredproducts',
      header: {
        components: [
          {
            uid: 'filteredproducts-header-main',
            name: 'MainHeader',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderDefault',
          },
          {
            uid: 'filteredproducts-header-banners',
            name: 'CarouselBanners',
            importRoute: 'Carousel',
            defaultProps: 'CarouselBanners',
          },
        ],
      },
      body: {
        components: [
          {
            uid: 'header-body-list',
            name: 'HeaderList',
            importRoute: 'HeaderList',
            defaultProps: 'HeaderListFiltered',
          },
          {
            uid: 'filteredproducts-body-list',
            name: 'Catalogue',
            importRoute: 'ItemList',
            defaultProps: 'ItemListFilteredProducts',
            props: {
              template: '{{templates.list.MainCatalogList}}',
            },
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'filteredproducts-footer-main',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterDefault',
          },
        ],
      },
      modals: [
        DefaultModalContainerNames.SEARCH_PRODUCT,
        DefaultModalContainerNames.PRODUCT_DETAIL,
        DefaultModalContainerNames.FILTERS_CLEAR_CONFIRM,
        DefaultModalContainerNames.FILTERS_LIST,
        DefaultModalContainerNames.CHOSEN_FREEGOODS,
      ],
    },
    SuggestedProducts: {
      uid: 'suggestedproducts',
      type: 'catalog',
      title: 'Suggested Products',
      slug: 'suggestedproducts',
      header: {
        components: [
          {
            uid: 'suggestedproducts-header-main',
            name: 'MainHeader',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderDefault',
          },
        ],
      },
      body: {
        components: [
          {
            uid: 'suggestedproducts-header-body',
            name: 'MainHeader',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderSuggestedProducts',
          },
          {
            uid: 'suggestedproducts-body-list',
            name: 'Catalogue',
            importRoute: 'ItemList',
            defaultProps: 'ItemListSuggestedProducts',
            props: {},
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'filteredproducts-footer-main',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterDefault',
          },
        ],
      },
      modals: [
        DefaultModalContainerNames.SEARCH_PRODUCT,
        DefaultModalContainerNames.PRODUCT_DETAIL,
        DefaultModalContainerNames.CHOSEN_FREEGOODS,
      ],
    },
    CartSummary: {
      uid: 'cartsummary',
      title: 'Cart Summary',
      slug: 'summary',
      type: 'summary',
      header: {
        components: [
          {
            uid: 'shoppingcart-header-001',
            name: 'Main Header',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderSummary',
          },
        ],
      },
      body: {
        components: [
          {
            defaultProps: 'AlertOnSummaryMinAmount',
            uid: 'summary-alert-min-amount',
            importRoute: 'Alert',
            name: 'AlertSummaryMinAmount',
          },
          {
            uid: 'summary-body-001',
            name: 'Summary Cart List',
            importRoute: 'SummaryList',
            defaultProps: 'SummaryListDefault',
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'summary-footer-001',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterSummary',
          },
        ],
      },
      modals: [],
    },
    CategoriesNav: {
      uid: 'categoriesnav',
      type: 'categoriesnav',
      title: 'Categories',
      slug: 'categoriesnav',
      header: {
        components: [
          {
            uid: 'categorylist-header-001',
            name: 'MainHeader',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderCategoriesNav',
          },
        ],
      },
      body: {
        components: [
          {
            uid: 'suggestedproducts-header-body',
            name: 'CategoriesList',
            importRoute: 'CategoriesList',
            defaultProps: 'CategoriesListDefault',
          },
        ],
      },
      footer: {
        components: [],
      },
      modals: [
        DefaultModalContainerNames.SEARCH_PRODUCT,
        DefaultModalContainerNames.PRODUCT_DETAIL,
        DefaultModalContainerNames.CHOSEN_FREEGOODS,
      ],
    },
  },
  modals: {
    [DefaultModalContainerNames.SEARCH_PRODUCT]: {
      uid: 'searchproducts',
      title: 'Search Products',
      type: 'summary',
      slug: 'searchproducts',
      header: {
        components: [
          {
            uid: 'searchproducts-header-001',
            name: 'Search Bar',
            importRoute: 'SearchBar',
            defaultProps: 'SearchBarDefault',
          },
        ],
      },
      body: {
        components: [
          {
            uid: 'searchproducts-body-001',
            name: 'Search Results',
            importRoute: 'ItemList',
            defaultProps: 'ItemListSearchResults',
          },
        ],
      },
    },
    [DefaultModalContainerNames.PRODUCT_DETAIL]: {
      uid: 'productdetail',
      title: 'Product Detail',
      slug: 'productdetail',
      body: {
        components: [
          {
            uid: 'productdetail-body-001',
            name: 'Product Detail',
            importRoute: 'ItemCard',
            defaultProps: 'ItemCardProductDetailModal',
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'productdetail-footer-001',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterProductDetailModal',
          },
        ],
      },
    },
    [DefaultModalContainerNames.RECOMMENDED_PRODUCTS]: {
      uid: 'recommended-products',
      title: 'Recommended products',
      slug: 'recommendedProducts',
      header: {
        components: [
          {
            uid: 'main-header',
            name: 'Main Header',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderRecommendedProducts',
          },
        ],
      },
      body: {
        components: [
          {
            uid: 'products-list',
            name: 'Product List',
            importRoute: 'ItemList',
            defaultProps: 'ItemListRecommendedProducts',
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'main-footer',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterRecommendedProducts',
          },
        ],
      },
    },
    [DefaultModalContainerNames.RECOMMENDED_PRODUCT_DETAIL]: {
      uid: 'recommendedproductdetail',
      title: 'Recommended Product Detail',
      slug: 'recommendedproductdetail',
      body: {
        components: [
          {
            uid: 'productdetail-body-001',
            name: 'Product Detail',
            importRoute: 'ItemCard',
            defaultProps: 'ItemCardProductRecommendedDetail',
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'productdetail-footer-001',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterRecommendedProuctDetailModal',
          },
        ],
      },
    },
    [DefaultModalContainerNames.PREFILL_CART]: {
      uid: 'prefill-cart',
      title: 'Prefill Cart',
      slug: 'prefillcart',
      header: {
        components: [
          {
            uid: 'main-header',
            name: 'Main Header',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderPrefillCart',
          },
        ],
      },
      body: {
        components: [
          {
            uid: 'products-list',
            name: 'Product List',
            importRoute: 'ItemList',
            defaultProps: 'ItemListPrefillCart',
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'main-footer',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterPrefillCart',
          },
        ],
      },
    },
    [DefaultModalContainerNames.FILTERS_CLEAR_CONFIRM]: {
      uid: 'filtersclearconfirm',
      title: 'Filters Clear Confirm',
      slug: 'filtersclearconfirm',
      body: {
        components: [
          {
            uid: 'filtersclearconfirm-body-001',
            name: 'Modal Clear Title',
            importRoute: 'Text',
            defaultProps: 'TextFiltersClearTitle',
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'filtersclearconfirm-clear-001',
            name: 'Modal Clear button',
            importRoute: 'Button',
            defaultProps: 'ButtonFiltersClear',
          },
          {
            uid: 'filtersclearconfirm-cancel-001',
            name: 'Modal Clear cancel',
            importRoute: 'Button',
            defaultProps: 'ButtonFiltersCancel',
          },
        ],
      },
    },
    [DefaultModalContainerNames.FILTERS_LIST]: {
      uid: 'filterslistmodal',
      title: 'Filters Clear Confirm',
      slug: 'filterslistmodal',
      header: {
        components: [
          {
            uid: 'filters-header-001',
            name: 'Main Header',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderFilters',
          },
        ],
      },
      body: {
        props: {
          styles: {
            '& > div': {
              display: 'block',
            },
          },
        },
        components: [
          {
            uid: 'advancedfilters-body-cpt',
            name: 'AdvancedFilters',
            importRoute: 'AdvancedFilters',
            defaultProps: 'AdvancedFiltersDefault',
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'advancedfilters-clear-btn-cpt',
            name: 'Clear Filters Button',
            importRoute: 'Text',
            defaultProps: 'TextFilters',
          },
          {
            uid: 'advancedfilters-footer-cpt',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterFilters',
          },
        ],
      },
    },
    [DefaultModalContainerNames.LINKED_PRODUCTS]: {
      uid: 'linkedProducts',
      title: 'Linked Products',
      slug: 'linkedProductsModal',
      header: {
        components: [
          {
            uid: 'linked-products-header',
            name: 'Main Header',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderLinkedProducts',
          },
        ],
      },
      body: {
        components: [
          {
            uid: 'linked-product-items',
            name: 'Linked Product Items',
            importRoute: 'ItemList',
            defaultProps: 'ItemListLinkedProducts',
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'linked-products-footer',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterLinkedProducts',
          },
        ],
      },
    },
    [DefaultModalContainerNames.CHOSEN_FREEGOODS]: {
      uid: 'chosenFreegoods',
      title: 'Chosen Freegoods',
      slug: 'chosenFreegoodsModal',
      header: {
        components: [
          {
            uid: 'chosen-freegoods-header',
            name: 'Main Header',
            importRoute: 'MainHeader',
            defaultProps: 'MainHeaderChosenFreegoods',
          },
        ],
      },
      body: {
        components: [
          {
            uid: 'chosen-freegoods-items',
            name: 'Chosen Freegoods Items',
            importRoute: 'OptionsList',
            defaultProps: 'OptionsListChosenFreegoods',
          },
        ],
      },
      footer: {
        components: [
          {
            uid: 'linked-products-footer',
            name: 'Main Footer',
            importRoute: 'MainFooter',
            defaultProps: 'MainFooterChosenFreegoods',
          },
        ],
      },
    },
  },
  texts: {},
  templates: {
    list: {},
    components: {},
  },
}

export default defaultConfig
