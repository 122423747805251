import { ItemStatus } from '@engyalo/delivery-ui-components/lib/interfaces/enums'
import { useEffect, useState, useRef } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { useParams } from 'react-router-dom'
import { useLazyGetCatalog } from '../../graphQL/queries/getCatalog'
import { useAppDispatch, useAppSelector } from '..'
import { fetchProductsResponseError } from '../../utils/fetchProducts'
import { ICatalog } from '../../graphQL/queries/getCatalog/types'
import {
  setCategories,
  setCategoryStatus,
  setFetchingMore,
} from '../../redux/slices/categoriesnav'
import {
  EMPTY_STRING,
  FIRST_PAGE_NUMBER,
  PRODUCTS_PAGE_SIZE,
} from '../../consts/defaultConfigValues/defaultConstants'
import {
  ICatalogItem,
  ICategoryPagination,
  IGetProductsNavArgs,
  IProduct,
  PaginationForCategories,
} from '../../interfaces'
import { generateDataDogContext } from '../../utils/dataDog'
import {
  getFlattedCartItems,
  setItemsQuantity,
} from '../../utils/utilsForCatalogReducer'
import { castICatalogItems } from '../../utils/castICatalogItems'
import { getPaginationForCategories } from '../../utils/categoriesnav/getPaginationForCategories'

export const initialPagination: ICategoryPagination = {
  pageNumber: FIRST_PAGE_NUMBER,
  pageSize: PRODUCTS_PAGE_SIZE,
  hasMore: true,
}

function usePrevious(value: string) {
  const ref = useRef<string | null>(null)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const useGetProductsNav = () => {
  const [pagination, setPagination] = useState<PaginationForCategories>({})
  const dispatch = useAppDispatch()
  const sessionId = useAppSelector(
    (state) => state.defaultSlice.sessionId || '',
  )
  const storeName = useAppSelector(
    (state) => state.defaultSlice.storeName || '',
  )
  const config = useAppSelector((state) => state.defaultSlice.config)
  const cartItems = useAppSelector((state) => state.cartSlice.items)
  const groups = useAppSelector((state) => state.cartSlice.groups)
  const activePromotions = useAppSelector(
    (state) => state.cartSlice.activePromotions,
  )
  const currentCategories = useAppSelector(
    (state) => state.categoriesNavSlice.categories,
  )

  const { categoryName: categoryNameUrl = EMPTY_STRING } = useParams<{
    categoryName?: string
  }>()
  const prevCategory = usePrevious(categoryNameUrl)
  useEffect(() => {
    dispatch(setCategories(null))
    dispatch(setFetchingMore(false))
    setPagination({})
  }, [])

  const onCompleted = (data: ICatalog) => {
    const categoriesResponse: ICatalogItem[] = castICatalogItems(
      data.catalog,
      config,
    )

    const newPagination = getPaginationForCategories({
      initialPagination,
      currentPagination: pagination,
      categoriesResponse,
    })

    setPagination(newPagination)
    const updatedCategories: ICatalogItem[] =
      currentCategories?.map((categoryToCompare) => {
        const [categoryResponse] = categoriesResponse
        if (categoryToCompare.category === categoryResponse.category) {
          const existingProducts: IProduct[] =
            categoryToCompare.productsPrices || []
          const productsResponse: IProduct[] =
            categoryResponse?.productsPrices || []
          return {
            ...categoryResponse,
            productsPrices: [...existingProducts, ...productsResponse],
          }
        }
        return categoryToCompare
      }) || categoriesResponse

    const finalUpdadtedCategories = updatedCategories.filter(
      (category) => (category.productsPrices ?? []).length > 0,
    )
    const { flattedCartItems } = getFlattedCartItems({
      items: cartItems,
      groups: groups ?? [],
    })

    const { categories: result } = setItemsQuantity({
      categories: finalUpdadtedCategories,
      flattedCartItems,
      config,
      activePromotions,
    })
    dispatch(setCategories(result))
  }

  const [getCatalog] = useLazyGetCatalog({
    onError: fetchProductsResponseError,
    onCompleted,
    errorPolicy: 'all',
  })

  const main = async (args?: IGetProductsNavArgs) => {
    const categoryName = args?.categoryName || categoryNameUrl
    const { forced = false } = args || {}
    try {
      const validExistingPagination = forced
        ? initialPagination
        : pagination[categoryName]
      const { hasMore, pageNumber } =
        validExistingPagination || initialPagination
      const pageNumbeRequest = validExistingPagination
        ? pageNumber + 1
        : FIRST_PAGE_NUMBER
      const paginationRequest = {
        pageNumber: pageNumbeRequest,
        pageSize: PRODUCTS_PAGE_SIZE,
      }
      if (hasMore || forced) {
        if (!currentCategories) {
          dispatch(setCategoryStatus(ItemStatus.LOADING))
        }
        dispatch(setFetchingMore(true))
        await getCatalog({
          variables: {
            groupCategories: true,
            showChildrenCategories: !validExistingPagination,
            catalogStorefrontName: storeName,
            catalogSessionId: sessionId,
            totalPromotionsSessionId: sessionId,
            categoryName,
            pagination: paginationRequest,
          },
        })
      }
    } catch (error) {
      const context = generateDataDogContext({
        title: 'could not fetch products',
        extraInfo: { function: 'fetchProducts' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(error, context)
    } finally {
      dispatch(setCategoryStatus(ItemStatus.OK))
      dispatch(setFetchingMore(false))
    }
  }

  useEffect(() => {
    if (prevCategory && categoryNameUrl && prevCategory !== categoryNameUrl) {
      const prevParentID =
        currentCategories?.find(
          (categoryToCompare) => categoryToCompare.category === categoryNameUrl,
        )?.parentId || null
      const newCategories =
        currentCategories?.filter(
          (categoryToCompare) =>
            categoryToCompare.category !== prevCategory &&
            categoryToCompare.parentId !== prevParentID,
        ) || []
      dispatch(setCategories(newCategories))
      main({ categoryName: categoryNameUrl, forced: true })
    }
  }, [prevCategory, categoryNameUrl])
  return main
}

export default useGetProductsNav
