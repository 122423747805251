import { ActivePromotion } from '../../graphQL/commons'
import { ICartProduct, IConditionAction, IConfig } from '../../interfaces'
import { IProduct } from '../../interfaces/catalog'
import { setProductsQuantity } from '../utilsForCatalogReducer'
import { getMultiPromotionUiDetails } from './getMultiPromotionUiDetails'
import {
  conditionFilterVolumePromotions,
  getVolumePromotionsResume,
} from './getVolumePromotionsResume'

export interface ITransformSelectedProductArgs {
  product: IProduct
  config?: IConfig
  activePromotions: ActivePromotion[]
  flattedCartItems?: ICartProduct[]
}

const cartProductTransformers: IConditionAction<
  ITransformSelectedProductArgs,
  IProduct
>[] = [
  {
    condition: ({ config }) => !!config?.options?.productDetailView,
    transformAction: ({
      product,
      config,
      flattedCartItems,
      activePromotions,
    }) => {
      const [result] = setProductsQuantity({
        products: [product],
        config,
        flattedCartItems,
        activePromotions,
        treatAsMultiPromo: false,
      })

      return result
    },
  },
  {
    condition: ({ config }) => !config?.options?.productDetailView,
    transformAction: ({
      product,
      config,
      flattedCartItems,
      activePromotions,
    }) => {
      const [result] = setProductsQuantity({
        products: [product],
        config,
        flattedCartItems,
        activePromotions,
        treatAsMultiPromo: true,
      })

      return result
    },
  },
  {
    condition: ({ config, product: { promotions } }) =>
      !!config?.options?.productDetailView &&
      Array.isArray(promotions) &&
      promotions.length > 0,
    transformAction: ({
      product: { promotion, ...product },
      activePromotions,
      config,
    }) => {
      const volumePromotionsData = getVolumePromotionsResume({
        promotions: product.promotions!,
        activePromotions,
        config,
        productPrice: product.price || 0,
      })

      return {
        ...product,
        volumePromotionsResume: volumePromotionsData?.resume,
        promotion: volumePromotionsData?.promotionDetails || promotion,
      }
    },
  },
  {
    condition: ({ product: { promotions }, config }) =>
      !!config?.options?.productDetailView &&
      !!Array.isArray(promotions) &&
      promotions.length > 1 &&
      promotions.filter(
        (promotion) => !conditionFilterVolumePromotions(promotion),
      ).length > 0 &&
      !!config,
    transformAction: ({ product, config }) => {
      const promotionsNonVolume = product!.promotions!.filter(
        (promotion) => !conditionFilterVolumePromotions(promotion),
      )
      const promotionsDetails = getMultiPromotionUiDetails({
        config: config!,
        product: {
          ...product,
          promotions: promotionsNonVolume,
        },
      })
      return {
        ...product,
        promotionsDetails,
      }
    },
  },
]

export function transformSelectedProduct({
  product,
  config,
  flattedCartItems,
  activePromotions,
}: ITransformSelectedProductArgs): IProduct {
  const result = cartProductTransformers.reduce<IProduct>(
    (acc, { condition, transformAction }) => {
      const argsTransformers = {
        product: acc,
        config,
        activePromotions,
        flattedCartItems,
      }
      return condition(argsTransformers)
        ? transformAction(argsTransformers)
        : acc
    },
    product,
  )

  return result
}

export default transformSelectedProduct
