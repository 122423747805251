import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'regenerator-runtime/runtime'
import 'core-js/features/string/replace-all'
/* eslint-disable import/no-extraneous-dependencies */
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { install } from 'resize-observer'
import App from './App'
import reportWebVitals from './reportWebVitals'

if (!window.ResizeObserver) {
  install()
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
