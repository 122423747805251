import axios, { AxiosError } from 'axios'
import axiosRetry from 'axios-retry'
import { IConfig } from '../interfaces'
import apiConfig from '../api-config'
import defaultConfig from '../consts/defaultConfig'

/**
 * service that helps us to obtain the webview config.
 * @public
 * @function
 * @name getConfig
 * @param {string} customer
 */

const {
  basicConfig: { url },
} = apiConfig

const callRetries = 3
const callRetryDelay = () => 1000
const callRetryCondition = () => true

export const getConfig = (
  customer?: string,
  callbackOnError: () => void = () => {},
): Promise<{ data: IConfig; error?: AxiosError }> => {
  if (url) {
    const urlConfig = `${url}${customer}?jwt=${process.env.REACT_APP_CONFIG_TOKEN}`
    axiosRetry(axios, {
      retries: callRetries,
      retryDelay: callRetryDelay,
      retryCondition: callRetryCondition,
    })
    return axios
      .get(urlConfig, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY!,
        },
      })
      .then((response) => {
        const { data } = response
        const config = data?.data?.data || {}
        return { data: config }
      })
      .catch((error: AxiosError) => {
        return new Promise<{ data: IConfig; error?: AxiosError }>(
          (_, reject) => {
            reject(error)
            callbackOnError()
          },
        )
      })
  } else {
    return new Promise<{ data: IConfig; error?: AxiosError }>((resolve) => {
      resolve({ data: defaultConfig })
    })
  }
}

export default getConfig
