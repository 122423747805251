import type { ICustomTheme } from '@engyalo/delivery-ui-components/lib/interfaces/theme'
import type {
  ItemCardContentProps,
  ItemCardProps,
} from '@engyalo/delivery-ui-components/lib/ui/components/ItemCard'
import type { CartListProps } from '@engyalo/delivery-ui-components/lib/ui/components/CartList'
import type { ItemListProps } from '@engyalo/delivery-ui-components/lib/ui/components/ItemList'
import type { SnackbarProps } from '@engyalo/delivery-ui-components/lib/ui/components/Snackbar/Snackbar.types'
import { PriceProps } from '@engyalo/delivery-ui-components/lib/ui/components/Price'
import type { SessionDataArgs } from '../graphQL/queries/getSession/types'
import type { ICinnamonActions } from './actions'
import type DEFAULT_COMPONENTS_PROPS from '../consts/defaultConfigValues/defaultProps'
import type { IPlaceOrderFlow, UnresolvedRecord } from '.'

export interface IRoutes {
  route: string
  container: string
  exact?: boolean
}

export interface IComponentActionsProps {
  propName: string
  name: string
  params: UnresolvedRecord
}

export interface IComponentComputedProps {
  propName: string
  path: string
}

export interface Icomponent {
  uid: string
  name: string
  importRoute: string
  defaultProps?: keyof typeof DEFAULT_COMPONENTS_PROPS
  props?: UnresolvedRecord & {
    actions?: Array<IComponentActionsProps>
    computed?: Array<IComponentComputedProps>
    runOnLoad?: ICinnamonActions
    hideOnScroll?: boolean
  }
}

export interface IContainerSections {
  components: Array<Icomponent>
  props?: UnresolvedRecord
}

export interface IContainersBase {
  uid: string
  type?:
    | 'cart'
    | 'catalog'
    | 'catalogFixed'
    | 'categories'
    | 'filters'
    | 'summary'
    | 'categoriesnav'
    | 'productdetail'
  title: string
  slug: string
  // @Todo: Fixme! We should know the type for this
  hooks?: UnresolvedRecord
  header?: IContainerSections
  body: IContainerSections
  footer?: IContainerSections
  skipDefaults?: boolean
}

export interface IModal extends IContainersBase {
  props?: UnresolvedRecord & {
    actions?: Array<IComponentActionsProps>
    computed?: Array<IComponentComputedProps>
  }
}

export interface IModalsObject extends Record<string, IModal> {}

export interface IContainers extends IContainersBase {
  modals?: Array<string>
}

export interface IContainersObject extends Record<string, IContainers> {}

export interface ITextLibrary {
  [key: string]: {
    [key: string]: string
  }
}

export interface IMetatags {
  description?: string
  keywords?: string
  author?: string
  favicon?: string
  title?: string
  charset?: string
  lang?: string
}
export interface ITemplateLibrary {
  list: Record<string, string>
  components: {
    [name: string]: {
      [variant: string]: {
        [prop: string]: any
      }
    }
  }
}

export enum ErrorMessageTranslation {
  GetCart = 1,
  CatalogueEmptyData,
  InitialData,
  NonProvidedParams,
  ErrorOnInitialLoad,
}

export interface IAnalytics {
  googleAnalyticsId: string
  fbPixelId: string
}

export interface IGlobals {
  navMenu?: 'tabs' | 'dropdown'
  counter?: {
    step?: number
    activeInput?: boolean
    max?: number | null
    modifierMap?: Record<string, number>
    allowRetryWhenOutOfStock?: boolean
  }
  catalog?: {
    layout?: ItemListProps['layout']
    bodyFields?: ItemCardContentProps['fields']
    footerFields?: ItemCardContentProps['fields']
    fieldsToDisplay?: ItemCardContentProps['fieldsToDisplay']
  }
  price?: {
    packagePriceMap?: Record<string, string>
  } & Partial<PriceProps>
  unitPrice?: {
    showUnitPrice?: boolean
    unitsSize?: number | string
    unitsColor?: string
    unitsInline?: boolean
  }
  minAmount?: {
    showAmount?: boolean
    showIndicator?: boolean
  }
  tags?: ItemCardProps['tags']
  sideContent?: ItemCardProps['sideContent']
  cartLabels?: CartListProps['labelMap']
  imageConfig?: {
    resizeEnabled: boolean
    imageSizes: {
      cartImage: string
      catalogueImage: string
      suggestedItemImage: string
      productDetailImage: string
      searchImage: string
      filterImage: string
      frequentlyBoughtTogetherImage: string
    }
  }
  recommendedProducts: {
    allRecommendations: number
    widgetHome: number
  }
  prefillCart: {
    createOrder: boolean
    limit: number
  }
  placeOrderFlow: Partial<IPlaceOrderFlow>
  [otherProps: string]: any
}

export enum Localization {
  En = 'en',
  Es = 'es',
  Pt = 'pt',
  Zh = 'zh',
  EnUs = 'en-us',
  EsMx = 'es-mx',
  PtBr = 'pt-br',
  ZhCn = 'zh-cn',
}

export interface IConfig {
  routes: IRoutes[]
  defaultRoute?: string
  phoneNumber: string
  containers: IContainersObject
  logo?: string
  modals?: IModalsObject
  theme: ICustomTheme
  name: string
  botSlug: string
  globalErrorMessage: string
  texts: ITextLibrary
  metatags?: IMetatags
  templates: ITemplateLibrary
  globals?: IGlobals
  analytics?: IAnalytics
  options?: {
    localization?: Localization
    systemThemeMode?: boolean
    orderMinAmount?: number
    currency?: string
    currencySymbol?: string
    currencyCode?: string
    currencyPrefix?: string
    pagination?: boolean
    useModal?: boolean
    format?: string
    promotionPercentageFormat?: string
    currencyDisplay?: string
    placeholderImage?: string
    usePaginatedCategories?: boolean
    /**
     * Use total in cart groups, if false show subtotal
     */
    cartGroupingTotals?: boolean
    /**
     * Show freegoods as subitem in cart
     */
    freegoodsAsSubitem?: boolean
    /**
     * Show freegoods combo promo origin in cart
     */
    showFreegoodsComboOrigin?: boolean
    /**
     * Ignore subtotal on cart
     */
    ignoreSubtotal?: boolean
    /**
     * Show restrictions apply label on cart
     */
    hideCartRestrictions?: boolean
    splash?: {
      logo?: boolean | string
      label?: string
      hideIcon?: boolean
    }
    /**
     * Hide subcategories options
     */
    hideSubcategories?: boolean
    /**
     * Hide products on subcategories
     */
    hideSubCategoriesProducts?: boolean
    /**
     * shows image for subcategories list
     */
    showSubCategoriesImages?: boolean
    /**
     * Hide products on subcategories
     */
    productDetailView?: boolean
    /**
     * track eventsURL, works as feature flag and also as parameter for send events
     */
    trackEventsURL?: string | null
    /**
     * Pagination size for the catalog
     */
    paginationSize?: number
    /**
     * url for resizer service
     */
    resizeServiceUrl?: string
  }
}

export interface IModals
  extends Record<
    string,
    {
      show: boolean
      state?: UnresolvedRecord
      item?: string
    }
  > {}

export interface IDialog {
  show: boolean
  id?: string
  icon: {
    name: string
  }
  title:
    | string
    | {
        value: string
      }
  message: {
    value: string
  }
  buttons: any
}

export enum CheckoutRules {
  CART_CONDITION_PENDING_MAX_QTY = 'cartConditionPendingMaxQty',
  CART_CONDITION_PENDING_MIN_QTY = 'cartConditionPendingMinQty',
  CART_CONDITION_PENDING_MAX_AMOUNT = 'cartConditionPendingMaxAmount',
  CART_CONDITION_PENDING_MIN_AMOUNT = 'cartConditionPendingMinAmount',
  CART_CONDITION_PENDING_USER_VALIDATION = 'cartConditionPendingUserValidation',
  CART_CONDITION_PENDING_MIN_ADDITIONAL_MEASUREMENT = 'cartConditionPendingMinAdditionalMeasurement',
  CART_CONDITION_PENDING_MAX_ADDITIONAL_MEASUREMENT = 'cartConditionPendingMaxAdditionalMeasurement',
  CART_WARNING_CHECK_RETURNABLES = 'cartWarningCheckReturnables',
  ORDER_DAILY_LIMIT = 'orderDailyLimit',
  CART_CONDITION_CHECK_LINKED_PRODUCTS = 'cartConditionCheckLinkedProducts',
}

export enum AlertConstrains {
  cartConditionPendingMinQty = 'cartConditionPendingMinQty',
  cartConditionPendingMinAmount = 'cartConditionPendingMinAmount',
  cartConditionPendingMaxAmount = 'cartConditionPendingMaxAmount',
  cartConditionPendingMaxQty = 'cartConditionPendingMaxQty',
  creditLimitMaxAmount = 'creditLimitMaxAmount',
}
export type IAlertsStatus = Record<AlertConstrains, { isActive: boolean }>

export interface IAlert
  extends Pick<
    SnackbarProps,
    | 'open'
    | 'message'
    | 'title'
    | 'origin'
    | 'topOffset'
    | 'bottomOffset'
    | 'showIcon'
    | 'showCloseButton'
    | 'closeOnClick'
    | 'autoHideDuration'
    | 'severity'
    | 'variant'
    | 'color'
    | 'size'
    | 'align'
  > {
  key?: string
}

export type LoaderConfig = string | { name: string; delay: number }
export interface IParams
  extends Record<string, string | UnresolvedRecord | undefined> {
  location?: {
    pathname: string
  }
}

export enum SessionNotifications {
  STOCK_AVAILABLE = 'STOCK_AVAILABLE',
  AUTHENTICATION_NEEDED = 'AUTHENTICATION_NEEDED',
  SESSION_CREATED = 'SESSION_CREATED',
  ORDER_REMINDER = 'ORDER_REMINDER',
  ABANDONED_CART = 'ABANDONED_CART',
}

export type SessionNotificationsArray = Array<{
  type: SessionNotifications
  enabled: boolean
}>

export interface ISliceDefault {
  loaders: string[]
  loading: boolean
  loadingDelay: number
  config?: IConfig
  sessionId?: string
  storeName?: string
  modals?: IModals
  dialogs?: IDialog
  sessionData?: SessionDataArgs
  validators: any[]
  errorMessage?: string | null
  currentModalItem?: string
  globalError: boolean
  showErrorTitle: boolean
  excludedUiComponents: string[]
  cartAlerts: IAlertsStatus
  params: IParams
  secondaryLoader?: boolean
  showRecommendations: boolean
  showSuggestedItems: boolean
  alerts: IAlert[]
  onErrorCallback?: () => void
  sessionNotifications?: Record<SessionNotifications, boolean>
}

export enum LoadersType {
  INITIAL_DATA = 'INITIAL_DATA',
  STORE_CONFIG = 'STORE_CONFIG',
  CREATE_ORDER = 'CREATE_ORDER',
}

export type DefaultProps<T> = Partial<T> & {
  template?: string
  runOnLoad?: ICinnamonActions
  hideOnScroll?: boolean
  hideOnScrollOffset?: number
  reachBottomActions?: ICinnamonActions
  [prop: string]: any
}

export interface IGetConfigArgs {
  slug: string
  language?: Localization
}
