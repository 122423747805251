import { AppContainer, Message } from '@engyalo/delivery-ui-components'
import { IActionButton } from '@engyalo/delivery-ui-components/lib/interfaces/actions'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../hooks'

const NotFound = () => {
  const { sessionId, storeName } = useAppSelector((state) => state.defaultSlice)
  const navigate = useNavigate()

  const buttons: IActionButton[] = [
    {
      label: 'Ir al inicio',
      fullWidth: false,
      variant: 'text',
      actions: () => navigate(`/${storeName}/${sessionId}`),
    },
  ]

  return (
    <AppContainer>
      {storeName && sessionId && (
        <Message
          id="NotFound"
          icon={{
            name: 'MdLinkOff',
          }}
          title={{
            value: 'Página no encontrada',
          }}
          buttons={buttons}
        />
      )}
    </AppContainer>
  )
}

export default NotFound
