import { gql } from '@apollo/client'

/**
 * This query get's the values to be used for the advanced filters section.
 * @internal
 */

export const getFiltersValuesQuery = `query GetFiltersValues($storefrontName: String!, $pagination: PaginationInput) {
  brands(storefrontName: $storefrontName, pagination: $pagination) {
    name
  }
  categories(storefrontName: $storefrontName, pagination: $pagination) {
    name
  }
}`

export default gql`
  ${getFiltersValuesQuery}
`
