import { IProduct } from '../../interfaces'
import { addAlert } from '../../redux/slices/config'
import {
  recalculateValueFromInput,
  type ICalculateNewQuantity,
} from '../../utils/cart/recalculateValueFromInput'
import useAppDispatch from '../useAppDispatch'
import useAppSelector from '../useAppSelector'
import useAddItemToCart from './useAddItemToCart'
import useRemoveItemFromCart from './useRemoveItemFromCart'

interface IAdjustProductQuantity
  extends Pick<
    ICalculateNewQuantity,
    'originalValue' | 'inRangeValue' | 'jumpQty'
  > {
  product: IProduct
}

export const useAdjustProductQuantity = () => {
  const { addItemAction } = useAddItemToCart()
  const { removeItemAction } = useRemoveItemFromCart()
  const dispatch = useAppDispatch()

  const freegoodsAsSubitem = useAppSelector(
    (state) => !!state.defaultSlice.config?.options?.freegoodsAsSubitem,
  )

  const autoAdd = useAppSelector(
    (state) =>
      !!state.defaultSlice.sessionData?.configuration?.promotions?.autoAdd,
  )

  const currency = useAppSelector(
    (state) => state.defaultSlice.config?.options?.currencyPrefix || '',
  )

  const updateQuantityMessage = useAppSelector(
    (state) => state.defaultSlice.config?.texts?.cart.updateQuantityMessage,
  )

  const adjustProductQuantity = (
    args: IAdjustProductQuantity,
  ): void | number => {
    const {
      product,
      product: { quantity: value, promotionalQty, minQtyAllowed },
      ...restArgs
    } = args

    const newValue = recalculateValueFromInput({
      ...restArgs,
      promotionalQty,
      minQtyAllowed,
      freegoodsAsSubitem,
      autoAdd,
    })

    if (newValue !== args.inRangeValue) {
      dispatch(
        addAlert({
          message: updateQuantityMessage,
          showIcon: true,
          severity: 'info',
        }),
      )
    }

    if (product.quantity > newValue) {
      removeItemAction({
        product,
        value,
        newValue,
        currency,
      })
    } else {
      addItemAction({
        product,
        value,
        newValue,
      })
    }

    if (product.quantity === newValue) {
      return newValue
    }
  }

  return {
    adjustProductQuantity,
  }
}

export default useAdjustProductQuantity
