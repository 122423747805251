import {
  GetComplexPromotionsUiDetails,
  ICommonPromotionFields,
  IConditionAction,
  IPromotion,
  IPromotionUiCatalog,
} from '../../interfaces'
import { getPromotionFields } from '../common/getPromotionFields'
import formatPromotionMessage, {
  IFormatPromotionMessage,
} from '../formatPromotionMessage'
import formatPercentage from '../formatPercentage'

interface ITextsForDirect {
  defaultSaving: string
  defaultPromotionLabel: string
  directIndividualLabel: string
  directDefaultLabel: string
  directIndividualDescription: string
  directDefaultDescription: string
  directDifferentProductLabel: string
  directDifferentProductDescription: string
}

const transformers: IConditionAction<
  {
    data: IPromotion
    promotionFields: ICommonPromotionFields
    texts: ITextsForDirect
    formatArgs: IFormatPromotionMessage
    price?: number
    isSameProduct?: boolean
    isOneGroupOneProduct?: boolean
    isIndividualPromotion?: boolean
    itemExtraExists?: boolean
  },
  IPromotionUiCatalog
>[] = [
  {
    condition: ({
      isIndividualPromotion,
      isOneGroupOneProduct,
      isSameProduct,
    }) => !!isSameProduct && !!isOneGroupOneProduct && !!isIndividualPromotion,
    transformAction: ({
      data,
      texts: {
        defaultPromotionLabel,
        directIndividualLabel,
        directIndividualDescription,
        defaultSaving,
      },
      promotionFields: { name },
      formatArgs,
    }) => {
      const label = formatPromotionMessage({
        ...formatArgs,
        message: directIndividualLabel,
      })
      const description = formatPromotionMessage({
        ...formatArgs,
        message: directIndividualDescription,
      })

      const saving = formatPromotionMessage({
        ...formatArgs,
        message: defaultSaving,
      })
      return {
        data,
        showButton: true,
        title: defaultPromotionLabel,
        label,
        description,
        saving: name ? '' : saving,
      }
    },
  },
  {
    condition: ({ isOneGroupOneProduct, isSameProduct }) =>
      !!isSameProduct && !!isOneGroupOneProduct,
    transformAction: ({
      data,
      texts: { defaultPromotionLabel, directDefaultDescription, directDefaultLabel },
      formatArgs,
    }) => {
      const label = formatPromotionMessage({
        ...formatArgs,
        message: directDefaultLabel,
      })
      const description = formatPromotionMessage({
        ...formatArgs,
        message: directDefaultDescription,
      })

      return {
        data,
        showButton: true,
        title: defaultPromotionLabel,
        label,
        description,
      }
    },
  },
  {
    condition: ({ isOneGroupOneProduct, isSameProduct, itemExtraExists }) =>
      !isSameProduct && !!isOneGroupOneProduct && !!itemExtraExists,
    transformAction: ({
      data,
      texts: { defaultPromotionLabel, directDifferentProductDescription, directDifferentProductLabel },
      promotionFields: { itemsExtra, customerGetsGroups },
      formatArgs,
    }) => {
      const getsSku = customerGetsGroups[0]?.items[0]?.sku
      const itemExtra = itemsExtra?.find(({ sku }) => getsSku === sku)!

      const label = formatPromotionMessage({
        ...formatArgs,
        placeholders: {
          ...formatArgs.placeholders,
          product: itemExtra.name,
        },
        message: directDifferentProductLabel,
      })
      const description = formatPromotionMessage({
        ...formatArgs,
        placeholders: {
          ...formatArgs.placeholders,
          product: itemExtra.name,
        },
        message: directDifferentProductDescription,
      })

      return {
        data,
        showButton:true,
        title:defaultPromotionLabel,
        label,
        description,
        image: itemExtra.imageURL?.[0],
      }
    },
  },
]

export const getComplexDirectPromotionUiDetails: GetComplexPromotionsUiDetails =
  ({ promotion, config, activePromotion, price = 0 }) => {
    const promotionFields = getPromotionFields(promotion)
    const { customerGetsGroups, customerBuysGroups, itemsExtra } =
      promotionFields
    const { count: countActivePromotion = 1 } = activePromotion || {}
    const buysFirstItem = customerBuysGroups[0]?.items[0]
    const buysSku = buysFirstItem?.sku
    const buysQuantity = buysFirstItem?.quantity

    const getsFirstItem = customerGetsGroups[0]?.items[0]
    const getsSku = getsFirstItem?.sku
    const getsQuantity = getsFirstItem?.quantity
    const percentage = getsFirstItem?.percentage || 0
    const promotionPercentageFormat = config?.options?.promotionPercentageFormat

    const formattedPercentageDiscount = formatPercentage(
      percentage,
      promotionPercentageFormat,
    )
    const initSaving = price * percentage

    const isSameProduct = buysSku === getsSku
    const isOneGroupOneProduct =
      customerBuysGroups.length === 1 && customerGetsGroups?.length === 1
    const isIndividualPromotion = getsQuantity === 1 && buysQuantity === 1

    const itemExtraExists = itemsExtra?.some(({ sku }) => getsSku === sku)

    const formatArgs: IFormatPromotionMessage = {
      message: '',
      promotion,
      config,
      initSaving,
      saving: initSaving * countActivePromotion,
      placeholders: {
        discount: formattedPercentageDiscount,
        value: String(buysQuantity),
      },
    }

    const {
      defaultSaving = '',
      defaultPromotionLabel = '',
      directIndividualLabel = '',
      directDefaultLabel = '',
      directIndividualDescription = '',
      directDefaultDescription = '',
      directDifferentProductLabel = '',
      directDifferentProductDescription = '',
    } = config?.texts.promotions || {}

    const texts: ITextsForDirect = {
      defaultPromotionLabel,
      defaultSaving,
      directIndividualLabel,
      directDefaultLabel,
      directIndividualDescription,
      directDefaultDescription,
      directDifferentProductLabel,
      directDifferentProductDescription,
    }

    return (
      transformers
        .find(({ condition }) =>
          condition({
            data: promotion,
            promotionFields,
            texts,
            formatArgs,
            isIndividualPromotion,
            isOneGroupOneProduct,
            isSameProduct,
            itemExtraExists,
          }),
        )
        ?.transformAction({
          data: promotion,
          promotionFields,
          texts,
          formatArgs,
        }) || {
        label: texts.defaultPromotionLabel,
        description: '',
      }
    )
  }
