import { ApolloError } from '@apollo/client'
import { AlertMessageType } from './getAlertErrorMessage'

export const ABORT_ERROR = 'AbortError'
/**
 * Validates the error message from the Apollo client and returns the appropriate alert message type.
 * If the error is an abort error, it returns undefined.
 *
 * @param error - The ApolloError object to validate.
 * @param defaultError - The default alert message type to return if the error is not a network error.
 * @returns The alert message type to be displayed, or undefined if the error is an abort error.
 */
export const validateApolloErrorCartAction = (
  error: ApolloError,
  defaultError: AlertMessageType,
): AlertMessageType | undefined => {
  if (error.cause?.name !== ABORT_ERROR) {
    return error.networkError ? AlertMessageType.Network : defaultError
  }
}

export default validateApolloErrorCartAction
