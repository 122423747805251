/* eslint-disable no-use-before-define */
import { SubItems } from '@engyalo/delivery-ui-components/lib/interfaces/catalog'
import type {
  ActiveFee,
  FeeThreshold,
  IBundleExtraSanitized,
  ICartProduct,
  ICustomFields,
  ILinkedConditionalStock,
  IPromotionUiCart,
  ISummaryCustomFields,
} from '../../interfaces'

export interface Item {
  sku: string
  quantity: number
  inCartQuantity?: number
  promotionalQty: number
  minQtyAllowed?: number
  maxQtyAllowed?: number
  maxQtyAlert?: number
  brand?: string
  linkedConditionalStock?: ILinkedConditionalStock | null
  linkedProducts?: string[]
  price: number
  priceTotal?: number
  name: string
  description: string
  packageType?: string
  packagePrice?: number | null
  unitDivision?: string
  unitsPerPackage?: number
  divisionsByUnit?: number
  discount?: number
  discounts?: number
  imageURL?: string[]
  attributes: string[]
  promotion?: IPromotionUiCart
  promotions: IPromotion[]
  isHidden?: boolean
  readOnly?: boolean
  fees?: FeeThreshold[]
  totalFee?: number
  subItems?: SubItems
  itemsExtra?: ICartProduct[]
  bundle: IBundleType | null
  bundlesExtra: IBundleExtraType[] | IBundleExtraSanitized[] | null
  jumpQty: number | null
  extraPackages?: IPackage[] | null
  package: IPackage | null
  stock: number | null
}

export interface IGroup {
  groupBy: string[]
  items: Item[]
  total: number
  subtotal?: number
  customFields?: ICustomFields | ISummaryCustomFields | null
  visibleTotal: number
}

export interface IConditionalAppearance {
  condition: string | null
  itemName: string | null
}

export enum PromotionType {
  DIRECT = 'DIRECT',
  VOLUME = 'VOLUME',
  COMBO = 'COMBO',
  TOTAL = 'TOTAL',
  COMPLEX = 'COMPLEX',
}

export enum ComplexPromotionTypes {
  QUANTITY = 'QUANTITY',
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
  DIRECT_PERCENTAGE = 'DIRECT_PERCENTAGE',
  MIXED = 'MIXED',
}

export interface PromotionUsageLimit {
  perCustomer: number | null
  perOrder: number | null
}

export interface PromotionCustomerBuysValue {
  amount: number | null
  minimum: number | null
  quantity: number | null
  minimumAmount: number | null
}

export interface ComplexPromotionBuysProduct {
  amount: number
  quantity: number
  sku: string
  type: ComplexPromotionTypes
}

export interface ComplexPromotionBuysGroup {
  identifier: string | null
  type: ComplexPromotionTypes
  items: ComplexPromotionBuysProduct[]
}

export interface PromotionCustomerBuys {
  value: PromotionCustomerBuysValue | null
  items: string[]
  groups: ComplexPromotionBuysGroup[]
}

export interface PromotionCustomerGetsValue {
  amount: number | null
  percentage: number | null
  quantity: number | null
  cash: number | null
}

export interface ComplexPromotionGetsProduct {
  amount: number | null
  percentage: number | null
  quantity: number | null
  sku: string | null
  type: ComplexPromotionTypes | null
}

export interface ComplexPromotionGetsGroup {
  identifier: string | null
  type: ComplexPromotionTypes
  items: ComplexPromotionGetsProduct[]
}

export interface PromotionCustomerGets {
  groups: ComplexPromotionGetsGroup[]
  value: PromotionCustomerGetsValue | null
  items: string[] | null
  itemsExtra: IProductCatalog[] | null
}

export interface IPackage {
  label: string
  name: string
  quantity: number
  price: number
  jumpQty: number | null
  minQty: number | null
  maxQty: number | null
  stock: number | null
  outOfStock: boolean | null
  value?: number
}

export interface IProductCatalog {
  id: string
  name: string
  sku: string
  category: string | null
  updatedAt: Date | null
  createdAt: Date | null
  customFields: any | null
  deactivationDate: Date | null
  isActive: boolean
  divisionsByUnit: string | null
  unitDivision: number | null
  unitsPerPackage: number | null
  packageType: string | null
  priority: number | null
  price: number | null
  imageURL: string[] | null
  size: number | null
  tags: string[] | null
  description: string
  categories: string[] | null
  metadata?: any | null
  sizeDescription?: string | null
  customizations: (IProductCatalog | null)[] | null
  attributes: string[]
  conditionalAppearance?: IConditionalAppearance | null
  promotions: IPromotion[] | null
  stock: number | null
  minQty: number | null
  maxQty: number | null
  outOfStock: boolean
  brand: string | null
  linkedProducts?: string[]
  hasDynamicPrice: boolean | null
  bundlesExtra: IBundleExtraType[] | null
  bundle: IBundleType | null
  jumpQty: number | null
  extraPackages: IPackage[] | null
  package: IPackage | null
}

export interface IPromotion {
  id: string | null
  type: PromotionType
  ranking?: number
  priority?: number
  name: string | null
  description: string | null
  usageLimit: PromotionUsageLimit | null
  customerBuys: PromotionCustomerBuys
  customerGets: PromotionCustomerGets
}

export interface IFilter {
  attribiutes?: string[]
  promotions?: boolean
  categories?: string[]
  brands?: string[]
}

export interface IPagination {
  pageNumber: number
  pageSize: number
}

export interface ICartProductData {
  sku: string | null
  quantity?: number
  replace?: boolean
  referrer?: string
  subItems?: SubItems
  package?: string
}

export interface ISkuQuantity {
  quantity: number | null
  sku: string | null
}

export interface IFreegoods {
  consumed: ISkuQuantity[] | null
  count: number | null
  items: ISkuQuantity[] | null
}

export interface ActivePromotion {
  complexConfiguration: IComplexConfiguration | null
  freeGoods: IFreegoods[] | null
  count: number
  pricePerItem: number
  promotion: IPromotion
  label: string | null
  itemsConsumed: Record<string, number> | null
}

export enum PromotionSelectionCriteria {
  CUSTOMER_SELECTION = 'CUSTOMER_SELECTION',
  REFUSED = 'REFUSED',
}

export interface IComplexConfiguration {
  selectBy: PromotionSelectionCriteria
  selectedGroupIdentifier: string | null
}

export interface IDeferredPromotion {
  count: number | null
  complexConfiguration: IComplexConfiguration | null
  promotion: IPromotion
}

export interface CartProductResponse {
  items: Item[]
  groups?: IGroup[]
  total: number
  subtotal: number
  id: string
  status: string
  createdAt: string
  updatedAt: string
  activePromotions: ActivePromotion[]
  deferredPromotions: IDeferredPromotion[] | null
  activeFees: ActiveFee[]
  totalFee: number
}

export interface ICheckoutRule {
  checkoutRule: {
    ruleType: string
    data: JSON
  }
}

export enum ICheckoutRulesConstraints {
  CART_WARNING_CHECK_RETURNABLES = 'cartWarningCheckReturnables',
  CART_CONDITION_PENDING_USER_VALIDATION = 'cartConditionPendingUserValidation',
}

export enum BundleDisplayOptionsEnum {
  NAME = 'NAME',
  DESCRIPTION = 'DESCRIPTION',
  UNITS_PER_PACKAGE = 'UNITS_PER_PACKAGE',
}

export interface IBundleType {
  displayOption: BundleDisplayOptionsEnum
  items: string[] | null
  label: string | null
  maxQtyPerSku: number | null
  maxSkus: number | null
  minQtyPerSku: number | null
  minSkus: number | null
  totalItems: number | null
}

export interface IBundleExtraType {
  name: string | null
  description: string | null
  unitsPerPackage: string | null
  price: number | null
  sku: string | null
  stock: number | null
}
