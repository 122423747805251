import { useEffect, useMemo, useRef } from 'react'
import { ApolloError } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import ReactGA from 'react-ga4'
import {
  nextPlaceOrderAction,
  setLinkedProducts,
  setLinkedProductsContinueButton,
  setShowLinkedProducts,
} from '../../redux/slices/cart'
import {
  CinnamonActionNames,
  DefaultModalContainerNames,
  TextLibrary,
} from '../../consts/defaultConfigValues/defaultConstants'
import { openModal } from '../../redux/slices/config'
import { setSynchronizingCart } from '../../redux/slices/catalog'
import {
  getFlattedCartItems,
  setProductsQuantity,
} from '../../utils/utilsForCatalogReducer'
import { useLazyLinkedProducts } from '../../graphQL/queries/linkedProducts'
import { ILinkedProductsResponse } from '../../graphQL/queries/linkedProducts/types'
import { generateDataDogContext } from '../../utils/dataDog'
import { ICartProduct, IProduct } from '../../interfaces'
import { useAddPrefillCart } from '../prefillCart/useAddPrefillCart'
import useAppSelector from '../useAppSelector'
import useAppDispatch from '../useAppDispatch'
import useDialog from '../useDialog'

/**
 * This hook fetches linked products and updates the state.
 * It also handles the response and shows the linked products modal.
 */
const useLinkedProducts = () => {
  const dispatch = useAppDispatch()
  const { addPrefillCart } = useAddPrefillCart()
  const { openDialog } = useDialog()
  const config = useAppSelector((state) => state.defaultSlice.config)
  const storefrontName = useAppSelector(
    (state) => state.defaultSlice.storeName || '',
  )
  const sessionUid = useAppSelector(
    (state) => state.defaultSlice.sessionId || '',
  )
  const activePromotions = useAppSelector(
    (state) => state.cartSlice.activePromotions,
  )
  const texts = useAppSelector((state) => state.defaultSlice.config?.texts)
  const items = useAppSelector((state) => state.cartSlice.items)
  const groups = useAppSelector((state) => state.cartSlice.groups)
  const linkedProductsStateShow = useAppSelector(
    (state) => state.cartSlice.linkedProducts,
  )
  const linkedProductsStateItems = useAppSelector(
    (state) => state.cartSlice.linkedProducts.items,
  )
  const linkedProductsStateContinueButton = useAppSelector(
    (state) => state.cartSlice.linkedProducts.continueButton,
  )
  const linkedProductsModal = useAppSelector(
    (state) =>
      state.defaultSlice.modals?.[DefaultModalContainerNames.LINKED_PRODUCTS],
  )
  // Get flatted cart items
  const cartItems = useMemo(() => {
    const { flattedCartItems } = getFlattedCartItems({ items, groups })
    return flattedCartItems
  }, [items, groups])
  const previousCartItems = useRef(cartItems)

  // Update linked products in state
  const updateLinkedProducts = (
    products: IProduct[],
    flattedCartItems: ICartProduct[],
  ) => {
    const newProducts = setProductsQuantity({
      products,
      flattedCartItems,
      config,
      activePromotions,
    })
    dispatch(setLinkedProducts(newProducts))
    return newProducts
  }

  // handle linked products response
  const onCompleted = ({ linkedProducts: result }: ILinkedProductsResponse) => {
    const products = updateLinkedProducts(result, cartItems)
    dispatch(setSynchronizingCart(false))
    // Show linked products modal if there are any
    if (products.length > 0 && linkedProductsStateShow) {
      dispatch(
        openModal({ container: DefaultModalContainerNames.LINKED_PRODUCTS }),
      )
      ReactGA.send({
        hitType: 'pageview',
        page: `/${DefaultModalContainerNames.LINKED_PRODUCTS}`,
      })
    }
    let adjustedCart = cartItems
    // Check if any linked product has exceeded stock
    const hasExceededStock = products.some((product) => {
      const { stock, quantity } = product
      return stock && stock < quantity
    })

    if (hasExceededStock) {
      adjustedCart = adjustedCart.map((cartItem) => {
        const { sku, quantity } = cartItem
        const { stock } =
          products.find((linkedProduct) => linkedProduct.sku === sku) || {}
        if (stock && stock < quantity) {
          return { ...cartItem, stock, quantity: stock }
        }
        return cartItem
      })
    }
    // Check if any linked product has been removed
    const hasRemovedItems = adjustedCart.some((product) => {
      const { sku, linkedConditionalStock } = product
      if (linkedConditionalStock) {
        return !products.find((linkedProduct) => linkedProduct.sku === sku)
      }
      return false
    })
    if (hasRemovedItems) {
      adjustedCart = adjustedCart.filter((cartItem) => {
        const { sku, linkedConditionalStock } = cartItem
        if (linkedConditionalStock) {
          return products.find((linkedProduct) => linkedProduct.sku === sku)
        }
        return true
      })
    }
    // if cart has been adjusted, make a prefill cart request
    if (hasExceededStock || hasRemovedItems) {
      addPrefillCart({
        items: adjustedCart,
        storefrontName,
        sessionUid,
        onCompleted: () => {
          openDialog({
            container: DefaultModalContainerNames.CART_ADJUSTMENT,
            id: 'stock-exceeded',
            title: { value: texts?.linkedProducts?.stockErrorTitle || '' },
            message: {
              value: texts?.linkedProducts?.stockErrorMessage || '',
            },
            icon: { name: 'MdWarningAmber' },
            buttons: [
              {
                label: texts?.actions?.accept || '',
                actions: {
                  cinnamonActions: [
                    {
                      name: CinnamonActionNames.CLOSE_DIALOG,
                    },
                  ],
                },
              },
            ],
          })
        },
      })
    } else if (products.length === 0) {
      dispatch(nextPlaceOrderAction())
    }
  }

  // handle linked products error
  const onError = (error: ApolloError) => {
    dispatch(setSynchronizingCart(false))
    dispatch(nextPlaceOrderAction())
    const context = generateDataDogContext({
      title: 'could not fetch frequently bought together response',
      extraInfo: { function: 'useFrequentlyBoughtTogether' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }

  const [getLinkedProductsQuery] = useLazyLinkedProducts({
    onCompleted,
    onError,
  })

  // Fetch linked products
  const fetchLinkedProducts = async () => {
    dispatch(setSynchronizingCart(true))
    await getLinkedProductsQuery({
      variables: {
        storefrontName,
        sessionUid,
      },
    })
  }

  // Initialize linked products
  const getLinkedProducts = async () => {
    const hasLinkedProducts = cartItems.some(
      (item) => item.linkedProducts?.length || item.linkedConditionalStock,
    )
    if (hasLinkedProducts) {
      await dispatch(setShowLinkedProducts(true))
      await fetchLinkedProducts()
    } else {
      dispatch(nextPlaceOrderAction())
    }
  }

  useEffect(() => {
    const hasLinkedProducts = cartItems.some(
      (item) => item.linkedConditionalStock,
    )

    if (hasLinkedProducts) {
      updateLinkedProducts(linkedProductsStateItems, cartItems)
      // Check if any product with linked products has been removed
      const hasToVerifyLinkedProducts = previousCartItems.current.some(
        (previousItem) => {
          const { sku, quantity, linkedProducts } = previousItem
          const currentItem = cartItems.find((cartItem) => cartItem.sku === sku)
          return (
            (!currentItem && linkedProducts?.length) ||
            (currentItem &&
              currentItem.linkedProducts?.length &&
              currentItem.quantity < quantity)
          )
        },
      )
      previousCartItems.current = cartItems

      if (hasToVerifyLinkedProducts) {
        fetchLinkedProducts()
      }
    }
    // Update label of continue button
    const label = hasLinkedProducts
      ? TextLibrary.LINKED_PRODUCTS_CONTINUE
      : TextLibrary.LINKED_PRODUCTS_CONTINUE_WITHOUT

    if (linkedProductsStateContinueButton !== label) {
      dispatch(setLinkedProductsContinueButton(label))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems])

  // When linked products modal is closed, set showLinkedProducts to false
  useEffect(() => {
    if (linkedProductsModal?.show === false) {
      dispatch(setShowLinkedProducts(false))
    }
  }, [linkedProductsModal?.show, dispatch])

  return { getLinkedProducts }
}

export default useLinkedProducts
