interface IAddPromotionalQtyToValue {
  valueToUpdate: number
  promotionalQty: number
  freegoodsAsSubitem: boolean
  autoAdd: boolean
}

export const addPromotionalQtyToValue = ({
  valueToUpdate,
  promotionalQty,
  freegoodsAsSubitem,
  autoAdd,
}: IAddPromotionalQtyToValue): number =>
  !autoAdd && freegoodsAsSubitem
    ? valueToUpdate + promotionalQty
    : valueToUpdate

export default addPromotionalQtyToValue
