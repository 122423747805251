import { FC, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { TemplateActionNames } from '../consts/defaultConfigValues/defaultConstants'
import type { ITemplate, ITemplateActions } from '../interfaces/templates'
import Default from './Default'
import type { IGenericFunction } from '../interfaces'
import useAddProductFromPromotion from '../hooks/catalog/useAddProductFromPromotion'

const ProductDetail: FC<ITemplate> = ({ ...props }) => {
  const { addProductFromPromotion } = useAddProductFromPromotion()
  const templateActions: ITemplateActions = {
    [TemplateActionNames.PRODUCT_DETAIL_ADD_FROM_PROMOTION]:
      addProductFromPromotion as IGenericFunction,
  }
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: `/${props.container.uid}` })
    window.fbq('track', 'PageView', { page: `/${props.container.uid}` })
  }, [])
  return <Default templateActions={templateActions} {...props} />
}

export default ProductDetail
