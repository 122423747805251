/* eslint-disable react/jsx-props-no-spreading, react/require-default-props */
import { FC, useMemo } from 'react'
import {
  Alert,
  AppContainer,
  Carousel,
  CatalogueList,
  HeaderList,
  MainFooter,
  MainHeader,
  TabsMenu,
} from '@engyalo/delivery-ui-components'
import ModalContainer from '../containers/ModalContainer'
import {
  useAppSelector,
  useCinnamonActions,
  useSuggestionItems,
} from '../hooks'
import Dialog from '../components/Dialog'
import { ITemplate, ITemplateActions } from '../interfaces/templates'
import { useModalsList } from '../hooks/templates/useModalsList'
import { IGenericFunction } from '../interfaces'
import CinnamonAlert from '../components/Alert'
import { getCinnamonPropsContainer } from '../hooks/useCinnamonProps'
import { TemplateActionNames } from '../consts/defaultConfigValues/defaultConstants'
import useCategoryFilters from '../hooks/catalog/useCategoryFilters'
import useCloseGlobalPromotion from '../hooks/catalog/useCloseGlobalPromotion'
import useProductsLazyLoader from '../hooks/categories/useProductsLazyLoader'
import useGetProductsNav from '../hooks/categoriesnav/useGetProductsNav'
import { usePrefillCart } from '../hooks/prefillCart/usePrefillCart'
import useBannersClick from '../hooks/useBannersClick'
import Dropdown from '@engyalo/delivery-ui-components/lib/ui/components/DropdownMenu/DropdownMenu'

const getPropsUsingUid = (name: string, section?: any) => {
  const component = section?.components.find((c: any) => c.uid === name)
  return component?.props
}

const CatalogFixed: FC<ITemplate> = ({
  container: xContainer,
  modals = {},
}) => {
  const { updateCategory, scrollToCategory, updateCurrentCategoryCatalog } =
    useCategoryFilters()
  const cinnamonActions = useCinnamonActions()
  const fetchSuggestedItems = useSuggestionItems()
  const { updatePrefillCartItem, addPrefillCartToCart } = usePrefillCart()
  const closeGlobalPromotions = useCloseGlobalPromotion()
  const getProductsNav = useGetProductsNav()
  const getProducts = useProductsLazyLoader()

  const bannersClick = useBannersClick()
  const templateActions: ITemplateActions = useMemo(
    () => ({
      [TemplateActionNames.CATALOG_SCROLL_TO_CATEGORY]:
        scrollToCategory as IGenericFunction,
      [TemplateActionNames.CATALOG_UPDATE_CATEGORY]:
        updateCategory as IGenericFunction,
      [TemplateActionNames.CATALOG_UPDATE_CATEGORY_CATALOG]:
        updateCurrentCategoryCatalog as IGenericFunction,
      [TemplateActionNames.CATALOG_BANNERS_CLICK]:
        bannersClick as IGenericFunction,
      [TemplateActionNames.SUGGESTED_ITEMS_FETCH_PRODUCTS]:
        fetchSuggestedItems as IGenericFunction,
      [TemplateActionNames.PREFILL_CART_UPDATE_ITEM]:
        updatePrefillCartItem as IGenericFunction,
      [TemplateActionNames.PREFILL_CART_TO_CART]:
        addPrefillCartToCart as IGenericFunction,
      [TemplateActionNames.CLOSE_GLOBAL_PROMOTIONS_ALERT]:
        closeGlobalPromotions as IGenericFunction,
      [TemplateActionNames.CATEGORIES_NAVIGATION_GET_PRODUCTS]:
        getProductsNav as IGenericFunction,
      [TemplateActionNames.CATEGORIES_LAZY_GET_PRODUCTS]:
        getProducts as IGenericFunction,
    }),
    [],
  )

  const actions = useMemo(
    () => ({
      ...templateActions,
      ...cinnamonActions,
    }),
    [templateActions, cinnamonActions],
  )

  const state = useAppSelector(
    (stateRedux) => stateRedux,
    (oldState, newState) =>
      Object.is(JSON.stringify(oldState), JSON.stringify(newState)),
  )

  const container = useMemo(
    () =>
      getCinnamonPropsContainer(xContainer, state, actions as ITemplateActions),
    [xContainer, state, actions],
  )

  const modalsList = useModalsList(container?.modals || [], modals)

  const mainHeaderProps = useMemo(
    () => getPropsUsingUid('cataloguedefault-header-main', container?.header),
    [container],
  )

  const carouselBannersProps = useMemo(
    () =>
      getPropsUsingUid('cataloguedefault-header-banners', container?.header),
    [container],
  )
  const categoryNavProps = useMemo(
    () => getPropsUsingUid('cataloguedefault-header-nav', container?.header),
    [container],
  )

  const categoryDropdownProps = useMemo(
    () => getPropsUsingUid('catalogue-header-dropdown', container?.header),
    [container],
  )
  const globalPromotionAlertProps = useMemo(
    () => getPropsUsingUid('GlobalPromotionAlert', container?.header),
    [container],
  )
  const creditLimitReachedProps = useMemo(
    () => getPropsUsingUid('comp-credit-limit-reached', container?.header),
    [container],
  )
  //body
  const carouselHeaderProps = useMemo(
    () => getPropsUsingUid('recomendations-header-carousel', container?.body),
    [container],
  )
  const recomendationsCarouselProps = useMemo(
    () => getPropsUsingUid('recomendations-body-carousel', container?.body),
    [container],
  )
  const headerListProps = useMemo(
    () => getPropsUsingUid('header-body-list', container?.body),
    [container],
  )

  const catalogueProps = useMemo(
    () => getPropsUsingUid('cataloguedefault-body-list', container?.body),
    [container],
  )

  // footer
  const mainFooterProps = useMemo(
    () => getPropsUsingUid('cataloguedefault-footer-main', container?.footer),
    [container],
  )

  return (
    <>
      <AppContainer
        header={{
          ...container?.header?.props,
          id: `${container?.uid}-header`,
          components: [
            mainHeaderProps ? <MainHeader {...mainHeaderProps} /> : null,
            carouselBannersProps ? (
              <Carousel {...carouselBannersProps} />
            ) : null,
            categoryNavProps ? <TabsMenu {...categoryNavProps} /> : null,
            categoryDropdownProps ? (
              <Dropdown {...categoryDropdownProps} />
            ) : null,
            globalPromotionAlertProps ? (
              <Alert {...globalPromotionAlertProps} />
            ) : null,
            creditLimitReachedProps ? (
              <Alert {...creditLimitReachedProps} />
            ) : null,
          ],
        }}
        body={{
          ...container?.body?.props,
          id: `${container?.uid}-body`,
          components: [
            carouselHeaderProps ? (
              <HeaderList {...carouselHeaderProps} />
            ) : null,
            recomendationsCarouselProps ? (
              <Carousel {...recomendationsCarouselProps} />
            ) : null,
            headerListProps ? <HeaderList {...headerListProps} /> : null,
            catalogueProps ? <CatalogueList {...catalogueProps} /> : null,
          ],
        }}
        footer={{
          ...container?.footer?.props,
          id: `${container?.uid}-footer`,
          components: [
            mainFooterProps ? <MainFooter {...mainFooterProps} /> : null,
          ],
        }}
      />
      {state.defaultSlice.modals &&
        modalsList.map(({ name, data }) => (
          <ModalContainer
            modalStatus={state.defaultSlice.modals || {}}
            key={data.uid}
            name={name}
            modal={data}
            actions={actions as Record<string, IGenericFunction>}
            state={state}
          />
        ))}
      <CinnamonAlert />
      <Dialog
        actions={actions as Record<string, IGenericFunction>}
        state={state}
      />
    </>
  )
}

export default CatalogFixed
