import Hotjar from '@hotjar/browser'
import { DefaultModalContainerNames } from '../consts/defaultConfigValues/defaultConstants'
import { BannerData } from '../interfaces/banners'
import { sanitizeSlug } from '../utils/sanitize'
import { sendGoogleEvent } from '../utils/sendGoogleAnalytics'
import useCategoryFilters from './catalog/useCategoryFilters'
import useProductDetail from './useProductDetail'
import usePushView from './usePushView'
import { URL_PREFFIX_CATEGORY } from './categoriesnav/constants'
import useModal from './useModal'
import useAppSelector from './useAppSelector'

const useBannersClick = () => {
  const productDetail = useProductDetail()
  const pushView = usePushView()
  const { openModal } = useModal()
  const { scrollToCategory } = useCategoryFilters()
  const storefrontName = useAppSelector((state) => state.defaultSlice.storeName)
  const sessionID = useAppSelector((state) => state.defaultSlice.sessionId)

  const isUsingProductDetailView = useAppSelector(
    (state) => !!state.defaultSlice.config?.options?.productDetailView,
  )

  const bannersClick = ({
    data: { type, reference, id },
    navigateToCategory,
  }: {
    data: BannerData
    navigateToCategory: boolean
  }) => {
    const categoryScrollName = 'categoryBannerScroll'
    const categoryNavName = 'categoryBannerNavigation'
    const productNavName = 'productBannerNavigation'
    let action = ''
    const homePath = `/${storefrontName}/${sessionID}`
    switch (type) {
      case 'PRODUCT':
        action = 'Banner redirect to Product'
        Hotjar.event(action)
        sendGoogleEvent(
          productNavName,
          action,
          `${reference}, Banner ID: ${id}`,
        )
        if (isUsingProductDetailView) {
          pushView({ path: `${homePath}/product/${reference}` })
        } else {
          pushView({ path: `${homePath}?sku=${reference}` })
          openModal({
            container: DefaultModalContainerNames.PRODUCT_DETAIL,
            item: reference,
          })
        }
        window.fbq('trackCustom', 'BannerRedirectToProduct', { sku: reference })
        productDetail({ sku: reference })
        break
      case 'CATEGORY':
        if (navigateToCategory) {
          // This is needed because home is replaced in CM.
          // If you're having issues, change home route in default config to match below
          action = 'Banner redirect to Category'
          Hotjar.event(action)
          sendGoogleEvent(
            categoryNavName,
            action,
            `${sanitizeSlug(reference)}, Banner ID: ${id}`,
          )
          window.fbq('trackCustom', 'BannerRedirectToCategory', {
            category: sanitizeSlug(reference),
          })
          pushView({
            path: `${homePath}/${URL_PREFFIX_CATEGORY}${reference}`,
          })
        } else {
          action = 'Banner scroll to Category'
          Hotjar.event(action)
          sendGoogleEvent(
            categoryScrollName,
            action,
            `${sanitizeSlug(reference)}, Banner ID: ${id}`,
          )
          scrollToCategory({ category: sanitizeSlug(reference) })
        }
        break
      case 'GENERAL':
      default:
        break
    }
  }

  return bannersClick
}

export default useBannersClick
