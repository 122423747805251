import useAppDispatch from './useAppDispatch'
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
} from '../redux/slices/config'
import { IModalActionsProps } from '../interfaces/actions'
import { DefaultModalContainerNames } from '../consts/defaultConfigValues/defaultConstants'
import useBack from './useBack'
import useSetSubitems from './catalog/useSetSubitems'

const useModal = () => {
  const dispatch = useAppDispatch()
  const goBack = useBack()
  const { clearSubitems } = useSetSubitems()

  const performModalActions = ({
    container,
  }: Pick<IModalActionsProps, 'container'>): void => {
    if (container === DefaultModalContainerNames.PRODUCT_DETAIL) {
      goBack({})
      clearSubitems()
    }
  }

  const openModal = ({ container, state, item = '' }: IModalActionsProps) => {
    dispatch(openModalAction({ container, state, item }))
  }

  const closeModal = ({ container, state }: IModalActionsProps) => {
    dispatch(closeModalAction({ container, state }))
  }

  return { openModal, closeModal, performModalActions }
}

export default useModal
