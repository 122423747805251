import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  IPrefillCartMutationParams,
  IPrefillCartMutationResponse,
} from './types'
import prefillCartMutationQuery from './mutation'

const usePrefillCartMutation = (
  options?: MutationHookOptions<
    IPrefillCartMutationResponse,
    IPrefillCartMutationParams
  >,
): MutationTuple<IPrefillCartMutationResponse, IPrefillCartMutationParams> => {
  return useMutation<IPrefillCartMutationResponse, IPrefillCartMutationParams>(
    prefillCartMutationQuery,
    {
      fetchPolicy: 'no-cache',
      ...options,
    },
  )
}

export default usePrefillCartMutation
