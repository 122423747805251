import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import cartRemoveProduct from './mutation'
import { IRemoveProductParams, IProductCart } from './types'

/**
 * Hook declaration to decrement quantity of a product in the cart.
 * This hooks validates the required params are present and then calls the graphql endpoint.
 * @param options: MutationHookOptions
 * @internal
 */
const useCartRemoveProduct = (
  options?: MutationHookOptions<IProductCart, IRemoveProductParams>,
): MutationTuple<IProductCart, IRemoveProductParams> => {
  return useMutation<IProductCart, IRemoveProductParams>(cartRemoveProduct, {
    fetchPolicy: 'no-cache',
    ...options,
  })
}

export default useCartRemoveProduct
