import { useCallback, useRef } from 'react'
import { ApolloError } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import Hotjar from '@hotjar/browser'
import { SubItems } from '@engyalo/delivery-ui-components/lib/interfaces/catalog'
import useCartAddProduct from '../../graphQL/mutations/cartAddProduct'
import useAppDispatch from '../useAppDispatch'
import { setSynchronizingCart } from '../../redux/slices/catalog'
import { debounceById } from '../../utils/debounce'
import { useGetCart } from './useGetCart'
import { IAddCartProductArgs, IGenericFunction } from '../../interfaces'
import useAppSelector from '../useAppSelector'
import { generateDataDogContext } from '../../utils/dataDog'
import { onCartResponse } from '../../utils/onCartResponse'
import { IProductCart } from '../../graphQL/mutations/cartAddProduct/types'
import { addAlert } from '../../redux/slices/config'
import getAlertErrorMessage, {
  AlertMessageType,
} from '../../utils/getAlertErrorMessage'
import { sendGoogleEvent } from '../../utils/sendGoogleAnalytics'
import { Referrers } from '../../consts/defaultConfigValues/defaultConstants'
import validateApolloErrorCartAction from '../../utils/validateApolloErrorCartAction'
import YaloAnalytics from '../../modules/analytics'
import { ITrackCartEventPayload } from '../../modules/interfaces'
import { IProduct } from '../../interfaces'
import { useLocation, useSearchParams } from 'react-router-dom'
import formatDataForAnalytics from '../../utils/formatDataForAnalytics'
import { calculateAddFinalValue } from '../../utils/cart/calculateAddFinalValue'

const callbackDebounceTime = process.env.REACT_APP_CART_ACTION_DEBOUNCE_TIME
  ? +process.env.REACT_APP_CART_ACTION_DEBOUNCE_TIME
  : 500

export const useAddItemToCart = () => {
  const dispatch = useAppDispatch()
  const abortControllers = useRef<Record<string, AbortController>>({})
  const state = useAppSelector((stateRedux) => stateRedux)
  const cart = useAppSelector((stateRedux) => stateRedux.cartSlice)
  const freegoodsAsSubitem = useAppSelector(
    (state) => !!state.defaultSlice.config?.options?.freegoodsAsSubitem,
  )
  const [searchParams] = useSearchParams()

  const autoAdd = useAppSelector(
    (state) =>
      !!state.defaultSlice.sessionData?.configuration?.promotions?.autoAdd,
  )

  const { subItemEdit } = state.catalogSlice
  const { config, sessionId, storeName, sessionData } = state.defaultSlice

  const getCartAction = useGetCart()

  const onCompleted = (data: IProductCart) => {
    if (data.cartAddProduct) {
      onCartResponse({
        data: data.cartAddProduct,
        state,
        dispatch,
      })
    }
  }

  const onError = (error: ApolloError) => {
    const alertType = validateApolloErrorCartAction(
      error,
      AlertMessageType.AddItem,
    )
    if (alertType) {
      const message = getAlertErrorMessage(alertType, config?.texts)
      dispatch(addAlert({ message }))
      getCartAction()
      const context = generateDataDogContext({
        title: 'Add product mutation error',
        extraInfo: { function: 'useAddItemToCart > onError' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(error, context)
    }
  }
  const [addProduct] = useCartAddProduct({
    onCompleted,
  })

  const location = useLocation()

  const execAddCartMutation = async (
    quantity: number,
    sku: string,
    product: IProduct,
    referrer?: Referrers,
    subItems?: SubItems,
    replace?: boolean,
    packageName?: string,
  ) => {
    try {
      dispatch(setSynchronizingCart(true))
      const controller = new window.AbortController()
      abortControllers.current[sku] = controller
      let actionLabel = 'Add to cart'
      if (referrer) {
        actionLabel = `Add to cart from ${referrer}`
      }
      const category = 'cart'
      Hotjar.event(actionLabel)
      sendGoogleEvent(category, actionLabel, sku, quantity)
      Hotjar.event(actionLabel)
      window.fbq('track', 'AddToCart', { sku, quantity })
      const currency = config?.options?.currency || ''
      const analyticsData: ITrackCartEventPayload = formatDataForAnalytics(
        location.pathname,
        quantity,
        cart,
        product,
        currency,
        sessionData?.customer?.phoneNumber || [''],
        sessionId,
        'addToCart',
        storeName,
        sessionData?.extraIds || [],
      )
      YaloAnalytics.trackCartEvent(analyticsData)
      await addProduct({
        variables: {
          cartAddProductSessionUid: sessionId || '',
          cartAddProductStorefrontName: storeName || '',
          cartAddProductData: {
            sku,
            quantity,
            replace,
            referrer: referrer || undefined,
            subItems,
            package: packageName || undefined,
          },
        },
        context: { fetchOptions: { signal: controller.signal } },
        onError: (error) => {
          onError(error)
        },
      })
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not add product to cart',
        extraInfo: { sku, quantity, function: 'execAddCartMutation' },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    } finally {
      dispatch(setSynchronizingCart(false))
    }
  }

  const callBackAddProductToCart = useCallback(
    debounceById(execAddCartMutation as IGenericFunction, callbackDebounceTime),
    [],
  )

  const addItemAction = ({
    product,
    referrer: referrerFromArgs,
    ...restArgs
  }: IAddCartProductArgs) => {
    const referrer = searchParams.get('referrer') || referrerFromArgs
    const {
      sku,
      name,
      package: productPackage,
      minQtyAllowed,
      promotionalQty,
    } = product
    try {
      const newValueResult = calculateAddFinalValue({
        minQtyAllowed,
        subItemEdit,
        name,
        packageNameFromCart: productPackage?.name || null,
        autoAdd,
        freegoodsAsSubitem,
        promotionalQty,
        ...restArgs,
      })

      if (newValueResult) {
        abortControllers.current[sku]?.abort()
        const {
          handledValue,
          cleanedSubItems,
          replaceCart,
          packageName,
          showAlert,
        } = newValueResult
        if (showAlert) {
          const alertType = AlertMessageType.outOfStock
          const message = getAlertErrorMessage(
            alertType,
            config?.texts,
            product.stock || 0,
          )
          dispatch(addAlert({ message }))
        }
        callBackAddProductToCart(
          sku,
          handledValue,
          sku,
          product,
          referrer,
          cleanedSubItems,
          replaceCart,
          packageName,
        )
      }
    } catch (exception) {
      const context = generateDataDogContext({
        title: 'could not add product to cart',
        extraInfo: {
          product,
          value: restArgs.value,
          newValue: restArgs.newValue,
          function: 'addItemAction',
        },
      })
      datadogRum.startView(context.viewName)
      datadogRum.addError(exception, context)
    }
  }
  return { addItemAction, execAddCartMutation }
}

export default useAddItemToCart
