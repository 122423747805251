import {
  ITrackCartEventPayload,
  IProductGenericBase,
  eventNames,
} from '../modules/interfaces'
import { IProduct, ICart, ICartProduct } from '../interfaces'
import { extraId } from '../graphQL/queries/getSession/types'

const formatDataForAnalytics = (
  location: string,
  quantity: number,
  cart: ICart | null,
  product: IProduct | null,
  currency: string | null,
  phoneNumber: Array<string> = [''],
  sessionId: string = '',
  eventName: eventNames,
  storefront: string = '',
  extraIds: extraId[] = [],
  cartProducts?: ICartProduct[],
): ITrackCartEventPayload => {
  const cartItems = cartProducts ?? cart?.items ?? []
  const totalAmount =
    cart?.total ??
    cartProducts?.reduce((acc, item) => acc + item.price * item.quantity, 0) ??
    0
  const productsTransformed: IProductGenericBase[] = cartItems.map(
    (item) =>
      ({
        quantity: item.quantity,
        name: item.name,
        brand: item.brand || '',
        id: item.id,
        price: item.price,
        category: '',
        sku: item.sku,
      }) as IProductGenericBase,
  )
  const productData = {
    quantity,
    sku: product?.sku ?? '',
    brand: product?.brand ?? '',
    category: product?.category ?? '',
    id: product?.id ?? '',
    name: product?.name ?? '',
    price: product?.price ?? 0,
  }
  let cartData = {
    cart: {
      product: productData,
      products: productsTransformed,
      currency: currency || '',
      total: totalAmount,
      totalItems: cartItems.length || 0,
    },
  }
  return {
    cartData,
    eventName,
    phoneNumber: phoneNumber[0],
    sessionId,
    viewName: location,
    storefront,
    extraIds,
  }
}

export default formatDataForAnalytics
