import { MAXIMUM_NUMBER_OF_GLOBAL_TOTAL_PROMOTIONS_ALERT } from '../../consts/defaultConfigValues/defaultConstants'
import { IPromotion as IPromotionResult } from '../../graphQL/commons'
import { IConfig, IPromotion } from '../../interfaces'
import { getPromotionFields } from '../common/getPromotionFields'
import formatPromotionMessage, {
  IFormatPromotionMessage,
} from '../formatPromotionMessage'

export const getTotalPromotionsLabel = (
  [promotion]: IPromotionResult[],
  config?: IConfig,
) => {
  const {
    customerGetsCash: cash,
    customerGetsPercentage: percentage,
    customerGetsQuantity: quantity,
    itemsExtra,
    description,
  } = getPromotionFields(promotion)
  if (description) {
    return description
  }
  const {
    globalPromotionPercentage,
    globalPromotionProduct,
    globalPromotionAmount,
  } = config?.texts.promotions || {}

  const itemExtra = itemsExtra?.[0]

  const formatArgs: IFormatPromotionMessage = {
    message: '',
    promotion: promotion as IPromotion,
    config,
    placeholders: {
      product: String(itemExtra?.name || ''),
      quantity: String(quantity),
      discount: cash || `${Number(percentage) * 100}%`,
    },
  }

  if (percentage) {
    return formatPromotionMessage({
      ...formatArgs,
      message: globalPromotionPercentage,
    })
  }

  if (quantity && itemExtra) {
    return formatPromotionMessage({
      ...formatArgs,
      message: globalPromotionProduct,
    })
  }

  return formatPromotionMessage({
    ...formatArgs,
    message: globalPromotionAmount,
  })
}

export const getMultipleTotalPromotionsLabel = (
  promotions: IPromotionResult[],
  config?: IConfig,
  maximumNumberOfPromotions = MAXIMUM_NUMBER_OF_GLOBAL_TOTAL_PROMOTIONS_ALERT,
) => {
  const cappedPromotions = promotions.slice(0, maximumNumberOfPromotions)

  const promotionsMessages = cappedPromotions.map((promotion) =>
    getTotalPromotionsLabel([promotion], config),
  )

  return promotionsMessages.join('\n\n')
}
