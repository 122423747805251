import { datadogRum } from '@datadog/browser-rum'
import { UpdateCartPayload } from '../redux/slices/cart/types'
import { manageErrors, openModal, setCartAlerts } from '../redux/slices/config'
import {
  CartQuantities,
  ErrorMessageTranslation,
  ICartGroup,
  ICartProduct,
  ICartResponseArgs,
} from '../interfaces'
import {
  getFlattedCartItems,
  setItemsQuantity,
  setProductsQuantity,
} from './utilsForCatalogReducer'
import { setFilterResults } from '../redux/slices/filters'
import { setPromotionUiDetailsCart } from './cart/setPromotionUiDetailsCart'
import {
  parseItemsToCartQuantities,
  getItemsInCart,
  getDisablePlaceOrder,
} from './utilsForCartReducer'
import {
  setCategoriesAndPagination,
  setFreegoodsConf,
  setProductDetail,
  setRecommendedProducts,
} from '../redux/slices/catalog'
import { generateDataDogContext } from './dataDog'
import { setActivePromotions, setCart } from '../redux/slices/cart'
import updateCurrentCategory from './updateCurrentCategory'
import { setSuggestedProducts } from '../redux/slices/suggestedProducts'
import {
  sanitizeCartProducts,
  sanitizeCartGroups,
} from './sanitizeCartProducts'
import {
  setDisabledCartGroups,
  setDisabledCartItems,
} from './setDisabledCartItems'
import { setCartInfo } from '../redux/slices/globalPromotions'
import { getGlobalPromotionCartInfo } from './cart/getGlobalPromotionCartInfo'
import { getFreegoodsFromDeferredPromotions } from './catalog/getFreegoodsFromPromotion'
import { DefaultModalContainerNames } from '../consts/defaultConfigValues/defaultConstants'
import { setPromotionUiDetailsCartSubitems } from './cart/setPromotionUiDetailsCartSubitems'
import { setCashPromotionUiDetailsCart } from './cart/setCashPromotionUiDetailsCart'
import { transformGroups } from './cart/transformGroups'
import transformSelectedProduct from './catalog/transformSelectedProduct'
import { setItemPriceTotal } from './cart/setItemPriceTotal'
import { setCategories } from '../redux/slices/categoriesnav'

type IActionTransformItems = (items: ICartProduct[]) => ICartProduct[]
type IActionTransformGroups = (items: ICartGroup[]) => ICartGroup[]

export const onCartResponse = ({
  data,
  categories,
  sessionData,
  dispatch,
  state,
}: ICartResponseArgs) => {
  try {
    if (data) {
      const {
        catalogSlice,
        defaultSlice: { sessionData: session, config },
        filtersSlice: { results: filteredProducts },
        suggestedProductsSlice: { suggestedProducts },
        categoriesNavSlice: { categories: categoriesNav },
      } = state
      const {
        items,
        subtotal,
        total,
        groups = [],
        activePromotions = [],
        activeFees = [],
        totalFee = 0,
        deferredPromotions = [],
      } = data
      const [showGlobalPromotion, globalPromotionMessage] =
        getGlobalPromotionCartInfo(activePromotions, config)

      dispatch(
        setCartInfo({
          show: showGlobalPromotion,
          message: globalPromotionMessage,
        }),
      )

      const {
        recommendedProducts,
        categories: stateCategories,
        currentCategory,
        selectedProduct,
      } = catalogSlice

      if (categories && categories.length === 0) {
        dispatch(
          manageErrors({
            errorMessageTranslation: ErrorMessageTranslation.CatalogueEmptyData,
            globalError: true,
          }),
        )
      }

      const currentCategories = categories || stateCategories
      const sessionInfo = sessionData || session

      const cartProducts = sanitizeCartProducts(
        items,
        !!sessionInfo?.configuration?.promotions?.autoAdd,
        !!sessionInfo?.configuration?.promotions?.disableFreeGoodsAsDiscount,
        config,
      )

      const sanitizedCartGroups = sanitizeCartGroups(
        groups,
        !!sessionInfo?.configuration?.promotions?.autoAdd,
        !!sessionInfo?.configuration?.promotions?.disableFreeGoodsAsDiscount,
        config,
      )

      const { flattedCartItems } = getFlattedCartItems({
        items: cartProducts,
        groups: sanitizedCartGroups,
      })

      const { categories: newCategories } = setItemsQuantity({
        categories: currentCategories,
        flattedCartItems,
        config,
        activePromotions,
      })
      const newRecommendedProducts = setProductsQuantity({
        products: recommendedProducts,
        flattedCartItems,
        config,
        activePromotions,
      })
      dispatch(
        setCategoriesAndPagination({
          categories: newCategories,
          paginationSize: state?.defaultSlice.config?.options?.paginationSize,
        }),
      )
      dispatch(setRecommendedProducts(newRecommendedProducts))
      if (filteredProducts) {
        const newFilteredProducts = setProductsQuantity({
          products: filteredProducts.productsPrices || [],
          flattedCartItems,
          config,
          activePromotions,
        })
        dispatch(
          setFilterResults({
            ...filteredProducts,
            productsPrices: newFilteredProducts,
          }),
        )
      }
      if (suggestedProducts) {
        const modifySuggestedProducts = setProductsQuantity({
          products: suggestedProducts || [],
          flattedCartItems,
          config,
          activePromotions,
        })
        dispatch(setSuggestedProducts(modifySuggestedProducts))
      }
      if (categoriesNav) {
        const { categories: updatedCategoriesNav } = setItemsQuantity({
          categories: categoriesNav,
          flattedCartItems,
          config,
          activePromotions,
        })
        dispatch(setCategories(updatedCategoriesNav))
      }
      updateCurrentCategory({
        categories: newCategories,
        currentCategory,
        dispatch,
      })

      const newProductsSkus = flattedCartItems.map(({ sku }) => sku)

      const quantitiesMap: CartQuantities = parseItemsToCartQuantities({
        items: flattedCartItems,
      })

      const itemsTotal = getItemsInCart(flattedCartItems)
      const totalFeeResult =
        totalFee || activeFees?.reduce((acc, { total: t = 0 }) => acc + t, 0)

      const actionsTransformItems: IActionTransformItems[] = [
        (productsToTransform) =>
          setDisabledCartItems({ items: productsToTransform }),
        (productsToTransform) =>
          !config?.options?.freegoodsAsSubitem
            ? setPromotionUiDetailsCart({
                cartProducts: productsToTransform,
                activePromotions,
                config,
              })
            : productsToTransform,
        (productsToTransform) =>
          config?.options?.freegoodsAsSubitem
            ? setPromotionUiDetailsCartSubitems({
                cartProducts: productsToTransform,
                activePromotions,
                config,
              })
            : productsToTransform,
        (productsToTransform) =>
          setCashPromotionUiDetailsCart({
            cartProducts: productsToTransform,
            activePromotions,
            config,
          }),
        (productsToTransform) =>
          setItemPriceTotal({
            cartProducts: productsToTransform,
            activePromotions,
            config,
          }),
      ]

      const itemsResult: ICartProduct[] = actionsTransformItems.reduce<
        ICartProduct[]
      >(
        (accumulatedCartProducts, action) => action(accumulatedCartProducts),
        cartProducts,
      )

      const actionsTransformGroups: IActionTransformGroups[] = [
        (groupsToTransform) =>
          setDisabledCartGroups({ groups: groupsToTransform }),
        (groupsToTransform) =>
          !config?.options?.freegoodsAsSubitem
            ? transformGroups({
                transformer: setPromotionUiDetailsCart,
                groups: groupsToTransform,
                params: {
                  activePromotions,
                  config,
                },
              })
            : groupsToTransform,
        (groupsToTransform) =>
          config?.options?.freegoodsAsSubitem
            ? transformGroups({
                transformer: setPromotionUiDetailsCartSubitems,
                groups: groupsToTransform,
                params: {
                  activePromotions,
                  config,
                },
              })
            : groupsToTransform,
        (groupsToTransform) =>
          transformGroups({
            transformer: setCashPromotionUiDetailsCart,
            groups: groupsToTransform,
            params: {
              activePromotions,
              config,
            },
          }),
      ]

      const groupsResult = actionsTransformGroups.reduce<ICartGroup[]>(
        (accumulatedCartGroups, action) => action(accumulatedCartGroups),
        sanitizedCartGroups,
      )

      const result: UpdateCartPayload = {
        items: itemsResult,
        groups: groupsResult,
        itemsTotal,
        total,
        subtotal: config?.options?.ignoreSubtotal ? total : subtotal,
        disablePlaceOrder: getDisablePlaceOrder(
          total,
          itemsTotal,
          (e) => dispatch(setCartAlerts(e)),
          sessionInfo,
        ),
        productsSkus: newProductsSkus,
        quantitiesMap,
        activeFees,
        totalFee: totalFeeResult,
      }
      dispatch(setActivePromotions(activePromotions))
      dispatch(setCart(result))

      if (selectedProduct) {
        const product = transformSelectedProduct({
          product: selectedProduct,
          config,
          flattedCartItems,
          activePromotions,
        })
        dispatch(setProductDetail({ product }))
      }

      const deferredPromotion =
        getFreegoodsFromDeferredPromotions(deferredPromotions)

      if (deferredPromotion) {
        const { promotion, freegoods, isConfirmDisabled } = deferredPromotion

        if (freegoods.length > 0) {
          dispatch(
            setFreegoodsConf({
              promotion,
              freegoods,
              isConfirmDisabled,
            }),
          )
          dispatch(
            openModal({
              container: DefaultModalContainerNames.CHOSEN_FREEGOODS,
            }),
          )
        }
      }
    }
  } catch (error) {
    const context = generateDataDogContext({
      title: 'could not get cart response',
      extraInfo: { function: 'onCartResponse' },
    })
    datadogRum.startView(context.viewName)
    datadogRum.addError(error, context)
  }
}
